import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { ASSETS, CHAINS } from "../constants";

export const getBalanceNumber = (balance, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  return displayBalance.toNumber();
};

// export const getDisplayBalance = (balance, decimals = 18) => {
//   const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
//   if (displayBalance.lt(1)) {
//     return displayBalance.toPrecision(4);
//   } else {
//     return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   }
// };

export const getDisplayBalance = (balance, decimals = 18) => {
  if (!balance) {
    return ''
  }
  return ethers.utils.formatUnits(balance, decimals)
}

export const getFullDisplayBalance = (balance, decimals = 18) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed();
};

export const formatAddress = (address) => {
  return address.slice(0, 6) + "..." + address.slice(-6);
};

export const getCurrentChain = (chainId) => {
  const chains = Object.keys(CHAINS).map((chain) => CHAINS[chain]);
  const chain = chains.find((chain) => chain.chainId === chainId);

  return chain;
};

export const getDateString = (timestamp) => {
  const date = new Date(timestamp * 1000)
  return date.toLocaleString()
}

export const getChainByBridgeId = (bridgeChainId) => {
  const chains = Object.keys(CHAINS).map((chain) => CHAINS[chain]);
  const chain = chains.find((chain) => chain.bridgeId === bridgeChainId);

  return chain;
};

export const getTokenByResourceId = (resourceID) => {
  const assets = Object.keys(ASSETS).map((asset) => ASSETS[asset]);
  const asset = assets.find((asset) => asset.resourceId.toLowerCase() === resourceID.toLowerCase());

  return asset;
};

export const getChainsList = () => {
  const chains = Object.keys(CHAINS).map((chain) => CHAINS[chain]);
  return chains;
};
