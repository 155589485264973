import React from "react";
import { Tooltip as PrimeTooltip } from 'primereact/tooltip';

import "./styles.scss";

const Tooltip = ({text}) => {
  return (
    <>
        <PrimeTooltip target=".custom-target-icon" />
        <span className="tooltip-icon">
          <i className="tooltip-i custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge" data-pr-tooltip={text} data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '2rem', cursor: 'pointer' }}>
            <div severity="info"></div>
          </i>
        </span>
    </>
  );
};

export default Tooltip;
