import { useCallback, useEffect, useState } from "react";

import { web3Modal } from "../utils/web3Modal";

const useWallet = () => {
  const [provider, setProvider] = useState(null);

  const loadWeb3Modal = useCallback(async () => {
    try {
      const newProvider = await web3Modal.connect();

      // Subscribe to chainId change
      newProvider.on("chainChanged", async (chainId) => {
        setProvider(null);
        await loadWeb3Modal();
      });

      // Subscribe to chainId change
      newProvider.on("accountsChanged", async (chainId) => {
        setProvider(null);
        await loadWeb3Modal();
      });

      setProvider(newProvider);
    } catch (error) {
      console.log(
        "%cMyProject%cline:20%cerror",
        "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
        "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
        "color:#fff;background:rgb(227, 160, 93);padding:3px;border-radius:2px",
        error
      );
    }
  }, []);

  const logoutOfWeb3Modal = async () => {
    setProvider(null);
  };

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  return { provider, loadWeb3Modal, logoutOfWeb3Modal };
};

export default useWallet;
