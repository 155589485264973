import erc20Abi from "./abis/erc20.json";
import ownableAbi from "./abis/ownable.json";
import iDemodyFiFactoryAbi from "./abis/IDemodyFiFactory.json";
import iDemodyFiRouterAbi from "./abis/IDemodyFiRouter.json";
import iDemodyFiPairAbi from "./abis/IDemodyFiPair.json";
import iDemodyFiERC20Abi from "./abis/IDemodyFiERC20.json";
import iERC20SAbi from "./abis/IERC20S.json";
import iPSBridgeERC20Abi from "./abis/IPSBridgeERC20.json";

const abis = {
  erc20: erc20Abi,
  ownable: ownableAbi,
  iDemodyFiFactory: iDemodyFiFactoryAbi,
  iDemodyFiRouter: iDemodyFiRouterAbi,
  iDemodyFiPair: iDemodyFiPairAbi,
  iDemodyFiERC20: iDemodyFiERC20Abi,
  iERC20S: iERC20SAbi,
  iPSBridgeERC20: iPSBridgeERC20Abi,
};

export default abis;
