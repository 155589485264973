// import IconButton from "@mui/material/IconButton";
// import Paper from "@mui/material/Paper";
// import InputBase from "@mui/material/InputBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Activity, ExternalLink as LinkIconFeather } from "react-feather";
// import { ExternalLink } from "./Theme";
import { Button as RebassButton } from "rebass/styled-components";
import styled, { keyframes } from "styled-components";
// import { ExternalLink } from "./Theme";
import TradeMainImage from "../assets/bg2.png";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";
import { media } from "./theme";
import { Messages } from "primereact/messages";

export const Header = styled.header`
  background-color: #282c34;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: white;
`;
export const NavSpan = styled.span`
  display: flex;
  flex-grow: 1;
  margin-left: 2rem;
  margin-top: 0.3rem;

  ${media.tablet.max(`
  display: none;
  `)}
`;
export const TradeDropdown = styled(Dropdown)`
&.p-dropdown .p-dropdown-label {
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */
padding-left: 18px;
display: flex;
align-items: center;
font-feature-settings: 'pnum' on, 'lnum' on;

color: #7E8BA5;
}
&.p-dropdown {

  margin-left: 2rem;
  background: rgba(255, 255, 255, 0.1);
border-radius: 100px;
  border: 0px solid rgba(92, 198, 255, 0.15);
  border-radius: 100px;
  border-radius: 40px;
  width: 150px;
  padding-top: -0.8rem;
  height: 40px;
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 200% */

display: flex;
align-items: center;
font-feature-settings: 'pnum' on, 'lnum' on;

color: #BCD3FF;
${media.tablet.max(`
margin-left: 1rem;
`)}
&:focus {
  background: rgba(187, 231, 255, 0.11);
  border: 1px solid rgba(92, 198, 255, 0.15);
  outline: 0;
}
&:focus:visible {
  background: rgba(187, 231, 255, 0.11);
  border: 1px solid rgba(92, 198, 255, 0.15);
  outline: 0;
}
&.p-dropdown .p-dropdown-label.p-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  
  color: #7E8BA5;
  ${media.tablet.max(`
font-size: 9px;
  `)}
  &:focus {
  color: #BCD3FF;
}

}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: transparent;
  color: #7DD0FF;
}
&.p-dropdown:not(.p-disabled).p-focus{
  color: #BCD3FF;
  background: rgba(187, 231, 255, 0.11);
  border: 1px solid rgba(92, 198, 255, 0.15);
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}
&.p-inputtext {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  
  color: #7E8BA5;
}
&.p-dropdown .p-dropdown-panel {
  margin-top: 0.5rem;
  background: #202739;
  width: 210px;
  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  
  display: flex;
  align-items: center;
  
  color: rgba(188, 211, 255, 0.7);
  &:hover {
    color: #7DD0FF;
    background: transparent;
  }
  &:active {
    color: #7DD0FF;
    background: transparent;
  
  }
  &:focus {
    color: #7DD0FF;
    background: transparent;
  }
  }
}
&.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
/* identical to box height, or 150% */

display: flex;
align-items: center;

color: rgba(188, 211, 255, 0.7);
&:hover {
  color: rgba(188, 211, 255, 0.7);
  background: transparent;
}
&:active {
  color: rgba(188, 211, 255, 0.7);
  background: transparent;

}
&:focus {
  color: rgba(188, 211, 255, 0.7);
  background: transparent;
}
}
&.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(188, 211, 255, 0.7);
  overflow: hidden;
}
`;
export const TradeSearch = styled(InputText)`
  &.p-inputtext {
    padding-left: 2rem;
    background: rgba(255, 255, 255, 0.11);
    border: 0px solid rgba(92, 198, 255, 0.15);
    box-sizing: border-box;
    border-radius: 40px;
    width: 384px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    /* identical to box height, or 108% */

    display: flex;
    align-items: center;

    color: rgba(126, 139, 165, 0.5);
    ${media.tablet.max(`
width: 190px;
font-size: 9px;
`)}
    &:focus {
      color: #bcd3ff;
      outline: 0;
      background: rgba(187, 231, 255, 0.11);
      border: 1px solid rgba(92, 198, 255, 0.15);
    }
    &:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2rem transparent;
      box-shadow: 0 0 0 0.2rem transparent;
      border-color: transparent;
      background: rgba(187, 231, 255, 0.11);
      border: 1px solid rgba(92, 198, 255, 0.15);
    }
  }
  p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2rem transparent;
    box-shadow: 0 0 0 0.2rem transparent;
    border-color: transparent;
  }
`;
export const SearchSpan = styled.span`
  &.p-input-icon-right > i:last-of-type {
    right: 1rem;
    color: #7e8ba5;
  }
  ${media.largeMobile.max(`
  margin-left: 0.5rem;
  `)};
`;
export const Body = styled.body`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: calc(100vh - 70px);
`;

export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 10px;
`;

// export const Button = styled.button`
//   background-color: white;
//   border: none;
//   border-radius: 8px;
//   color: #282c34;
//   cursor: pointer;
//   font-size: 16px;
//   text-align: center;
//   text-decoration: none;
//   margin: 0px 20px;
//   padding: 12px 24px;

//   ${(props) => props.hidden && "hidden"} :focus {
//     border: none;
//     outline: none;
//   }
// `;

export const NavHeadings = styled.h4`
  margin-right: 3rem;
  color: #f8f8fe;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  cursor: pointer;
  ${media.tablet.max(`
    margin-right: 0rem;
    margin-left: 0.5rem;
    font-size: 9px;
    `)};
  ${media.largeMobile.max(`
    margin-right: 0rem;
    margin-left: 0.5rem;
    font-size: 7px;
    margin-top: 2rem;
    `)};
`;

export const Demodyfi = styled.img`
  margin-right: 0.4rem;
  ${media.largeMobile.max(`
    width: 10px;
    height: 10px;
    margin-top: 0.15rem;
    `)};
`;
export const FooterTextTwo = styled.a`
  font-style: normal;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #878dad;
  font-family: "Chillax-Medium" !important;
  cursor: pointer;
  text-decoration: none;
  ${media.tablet.max(`
    font-size: 8px;
    `)};
  ${media.largeMobile.max(`
   font-size: 5px;
   margin-left: 0.2rem;
    `)};
  &:hover {
    color: #7d8fd0;
  }
`;
export const FooterRow = styled.div`
display: flex;
  // ${media.tablet.min(`
  // margin-left: 12rem;
  //    `)}
  // ${media.tablet.max(`
  // margin-left: 12rem;
  //    `)}
  // ${media.largeMobile.max(`
  // margin-left: 0rem;
  //    `)}
    
`;
export const FooterIconRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.6rem 0.5rem;
  ${media.largeMobile.max(`
padding: 1rem 0rem;    `)};
`;
export const FooterText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  border-right: 0.5px solid rgba(166, 160, 187, 0.4);
  padding-right: 0.8rem;
  color: #878dad;
  height: 1.2rem;
  font-family: "Chillax-Medium" !important;
  ${media.tablet.max(`
    margin-left: 0rem;
    font-size: 8px;
    `)};
  ${media.largeMobile.max(`
    margin-left: 0rem;
    font-size: 5px;
    padding-right: 0.4rem;

    `)};
`;

export const ActivityIcon = styled(Activity)`
  color: #a6a0bb;
  height: 1rem;
  ${media.largeMobile.max(`
height: 0.6rem;
margin-top: 0.2rem;
`)};
`;
export const FooterAnalyticsText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  border-right: 0.5px solid rgba(166, 160, 187, 0.4);
  padding-right: 0.8rem;
  color: #878dad;
  height: 1.2rem;
  font-family: "Chillax-Medium" !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  ${media.tablet.max(`
    margin-left: 0rem;
    font-size: 8px;
    `)};
  ${media.largeMobile.max(`
    margin-left: 0rem;
    font-size: 5px;
    padding-right: 0.4rem;
    margin-right: 0rem;
    margin-left: 0rem;
    `)};
`;
export const FooterMain = styled.div`
  text-align: left;
  padding: 0rem 2rem;
  margin-left: 7rem;
`;
export const FooterDiv = styled.div`
  background-color: transparent;
  height: 3rem;
  border-top: 1px solid rgba(225, 225, 225, 0.3);
  color: white;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  border-top: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    #ffffff 50%,
    rgba(255, 255, 255, 0.08) 100%
  );
  border-image-slice: 1;
  justify-content: center;
  ${media.largeMobile.max(`
margin-left: 1rem;
margin-right: 1rem;    `)}
`;
export const FooterIcon = styled.div`
  color: #999999;
  font-size: 16px;
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
  background: rgba(48, 41, 72, 0.46);
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  &:hover {
    color: #fff;
  }
  ${media.largeMobile.max(`
  font-size: 10px;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
     `)}
`;
export const FirstSpan = styled.span`
  font-weight: 100;
`;
export const MainCard = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  padding: 34px 25px 44px;

  width: auto;

  background: #0b0a1b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin-top: 3rem;
  position: relative;
`;
export const NavbarDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0rem 3rem;
  ${media.tablet.max(`
  padding: 0;
     `)}
`;
export const NavText = styled.h4`
  font-family: Chillax-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 87% */
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #a6a0bb;
  margin-left: 2rem;
  opacity: 0.9;
  margin-top: 1.8rem;
  backdrop-filter: blur(4px);
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  line-height: 1.8rem;

  &:hover {
    background: linear-gradient(
      73.6deg,
      #91dcf0 6.19%,
      #25b0d7 25.05%,
      #fa8ffa 57.68%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1.2px solid;
    border-image: linear-gradient(
      73.6deg,
      #91dcf0 6.19%,
      #25b0d7 25.05%,
      #fa8ffa 57.68%
    );
    border-image-slice: 1;
  }
  // &:active {
  //   background: linear-gradient(
  //     73.6deg,
  //     #91dcf0 6.19%,
  //     #25b0d7 25.05%,
  //     #fa8ffa 57.68%
  //   );
  //   animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  //   animation-duration: 2.5s;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   border-bottom: 1.2px solid;
  //   border-image: linear-gradient(73.6deg,#91dcf0 6.19%,#25b0d7 25.05%,#fa8ffa 57.68%);
  //   border-image-slice: 1;
  //   padding-left: 0.6rem;
  //   padding-right: 0.6rem;
  //   line-height: 1.8rem;
  // }
`;
export const NavImg = styled.img`
  margin: 8px 8px;
  ${media.largeMobile.max(`
  width: 1rem;
      `)}
`;
export const IconDiv = styled.div`
  background: #3b3c4e;
  border-radius: 40px;
  width: 41px;
  height: 40px;
  margin-right: 3rem;
  margin-top: 1.7rem;
  ${media.largeMobile.max(`
  width: 32px;
  height: 30px;
  margin-right: 0.5rem;
      `)}
`;

export const WalletSection = styled.div`
  display: flex;
  margin-right: 0rem;
  ${media.tablet.max(`
      margin-right: 0rem;
    `)}
`;

export const NavMenu = styled(Menu)`
  &.p-menu .p-component .p-menu-overlay p-connected-overlay-enter-done {
    max-width: 9rem;
    min-width: 8.125rem;
    background: rgb(13, 12, 30);
    box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
      rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
    border-radius: 12px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    position: absolute;
    top: 5rem;
    right: 2rem;
    z-index: 100;
  }
`;

export const NavIconButton = styled.button`
  margin-right: 2rem;
  height: 38px;
  width: 38px;
  background-color: rgba(106, 121, 159, 0.18);
  border-radius: 100%;
  cursor: pointer;
  // padding: 0.5rem 0.7rem;
  padding: 0;
  margin-top: 1.7rem;
  border: none;
  background-color: rgba(106, 121, 159, 0.18);
  &:hover {
    opacity: 0.8;
  }
  // &:active {
  //   background: linear-gradient(#070618, #070618) padding-box,
  //     linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
  //       border-box;
  //   border: 1px solid transparent;
  //   outline: 0;
  // }
  // &:hover {
  //   background: linear-gradient(#070618, #070618) padding-box,
  //     linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
  //       border-box;
  //   outline: 0;
  //   border: 1px solid transparent;
  // }
  // &:focus-within {
  //   background: linear-gradient(#070618, #070618) padding-box,
  //     linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
  //       border-box;
  //   outline: 0;
  //   border: 1px solid transparent;
  // }
  ${media.tablet.max(`
  margin-top: 1.9rem;
    `)}
  ${media.largeMobile.max(`
    margin-top: 1.7rem;
    // padding: 0.4rem 0.6rem;
    padding: 0.4rem 0.5rem 0.5rem 0.5rem;
    width: 32px;
    height: 32px;
      `)}
`;
// export const SearchText = styled(InputBase)`
//   &.css-piqts5-MuiInputBase-root {
//     font-family: Chillax;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 13px;
//     /* or 93% */

//     display: flex;
//     align-items: center;

//     color: rgba(255, 255, 255, 0.6);
//   }
// `;
// export const MainSearch = styled(Paper)`
//   &.css-1vm0r4s-MuiPaper-root {
//     width: 556px;

//     margin-bottom: 2rem;
//     display: flex;
//     background: rgba(1, 0, 19, 0.25);
//     border-radius: 40px;
//   }
// `;
export const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 5rem;
  right: 2rem;
  z-index: 100;
  ${media.tablet.max(`
  display: none;
`)}
`;

export const MenuFlyoutTablet = styled.span`
  display: none;
  ${media.tablet.max(`
  min-width: 8.125rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 5rem;
  right: 2rem;
  z-index: 100;
`)}
`;

export const MenuItem = styled.a`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  color: #c3c5cb;
  text-decoration: none;
  :hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`;

export const LinkIconWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;

  :hover {
    text-decoration: none;
    opacity: 0.7;
  }

  :focus {
    outline: none;
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`;

export const LinkIcon = styled(LinkIconFeather)`
  height: 16px;
  width: 18px;
  margin-left: 10px;
  stroke: #6c7284;
`;

export const WalletButton = styled.button`
  background: linear-gradient(
      318.86deg,
      #563bd3 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #6fe38f 0%,
      rgba(133, 66, 244, 0.2) 95.83%
    );
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 14px;
  margin-right: 0.5rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 1.7rem;
  margin-left: -2rem;
  cursor: pointer;

  &:hover {
    background: linear-gradient(342deg, #6fe38f, rgba(133, 66, 244, 0.2) 53.86%),
      radial-gradient(
        8368% 83.68% at 0 0,
        #563bd3 0,
        rgba(123, 35, 195, 0) 150%
      );
  }
  ${media.largeMobile.max(`
 font-size: 9px;
 height: 0.6rem;
 margin-right: 0.6rem;
     `)}
  ${media.tablet.max(`
     margin-right: 0.6rem;
         `)}
`;
export const TabletMenuDiv = styled.div`
  font-size: 13px;
  font-family: "Poppins" !important;
  font-weight: 600;
`;
export const MenuWalletButton = styled.button`
  background: linear-gradient(
      318.86deg,
      #563bd3 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #6fe38f 0%,
      rgba(133, 66, 244, 0.2) 95.83%
    );
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 14px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 1.7rem;
  margin-left: -2rem;
  cursor: pointer;
  &:hover {
    background: linear-gradient(342deg, #6fe38f, rgba(133, 66, 244, 0.2) 53.86%),
      radial-gradient(
        8368% 83.68% at 0 0,
        #563bd3 0,
        rgba(123, 35, 195, 0) 150%
      );
  }
  ${media.largeMobile.max(`
 font-size: 9px;
 height:2rem;
 margin-right: 1rem;
     `)}
`;

export const DmodImg = styled.img`
  width: 1.8rem;
  height: auto;
  margin-right: 0.4rem;
`;

export const DmodImgTablet = styled.img`
  width: 1rem;
  height: 1.2rem;
  margin-top: 0.5rem;
  margin-right: 0.4rem;
`;

export const WalletDiv = styled.button`
  border: 0px;

  background: rgba(106, 121, 159, 0.18);
  border-radius: 100px;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 12.5px;
  color: white;
  padding: 0.5rem 2.5rem 0.5rem 1.2rem;
  height: 2.5rem;
  margin-top: 1.7rem;
  cursor: pointer;
  ${media.tablet.max(`
  font-size: 10px;
 display: none;
      `)}
`;
export const LogoDiv = styled.div`
  ${media.tablet.max(`
flex-grow: 1;
      `)}
`;
export const ConnectButtonText = styled.h4`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: bold;
  font-size: 12.5px;
  margin-right: 0.25rem;
  background: linear-gradient(
    73.6deg,
    #91dcf0 6.19%,
    #25b0d7 25.05%,
    #fa8ffa 57.68%
  );
  ${media.largeMobile.max(`
font-size: 8px;      `)}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const TabletConnectButtonText = styled.h4`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: bold;
  font-size: 12.5px;
  margin-right: 0.25rem;
  background: linear-gradient(
    73.6deg,
    #91dcf0 6.19%,
    #25b0d7 25.05%,
    #fa8ffa 57.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ApproveButton = styled.button`
  background: #171730;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  width: 100%;
  height: 52px;
  padding: 0.75rem 1rem;
  margin-top: 1.7rem;
  cursor: pointer;
  ${media.tablet.max(`
  `)}
`;
export const ApproveButtonText = styled.h4`
  margin-top: 0.4rem;
  margin-right: 0.5rem;
  background: linear-gradient(
    73.6deg,
    rgb(145, 220, 240) 6.19%,
    rgb(37, 176, 215) 25.05%,
    rgb(250, 143, 250) 57.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 100;
  ${media.largeMobile.max(`
  margin-top: 0.2rem;
      `)}
`;

export const ConnectButton = styled.button`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 1.7rem;
  cursor: pointer;
  ${media.tablet.max(`
  display: none;
  height: 2rem;
  `)}
`;
export const ConnectedButton = styled.button`
  background: #1f1f3e;
  align-items: center;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 1rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 1.7rem;
  margin-left: -2rem;
  cursor: pointer;
  display: flex;
  ${media.largeMobile.max(`
  font-size: 9px;
  height: 2rem;
  margin-right: 0.5rem;
  padding: 0.4rem 0.8rem;
      `)}
`;
export const DmodButton = styled.button`
  background: rgba(106, 121, 159, 0.18);
  align-items: center;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 14px;
  margin-right: 1rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 1px 16px 1px 8px;
  margin-top: 1.7rem;
  margin-left: 0rem;
  cursor: pointer;
  display: flex;
  ${media.tablet.max(`
display: none;
margin-right: 3rem
      `)}
  ${media.largeMobile.max(`
display: none;
      `)}
      &:hover {
    opacity: 0.8;
  }
`;
export const UserImg = styled.img`
  margin: 1.2px;
  ${media.largeMobile.max(`
  width: 1rem;
      `)}
`;
export const NetworkDiv = styled.div`
  background: rgba(1, 0, 19, 0.38);
  border: 1.5px solid rgba(23, 23, 44, 0.8);
  box-sizing: border-box;
  border-radius: 14px;
  height: 6.5rem;
  cursor: pointer;
  &:active {
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent;
    border-radius: 14px;
  }
  &:hover {
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent;
    border-radius: 14px;
  }
`;
export const ModalHeader = styled.div`
  ${media.largeMobile.max(`
font-size: 12px;
    `)}
`;

export const DialogDiv = styled(Dialog)`
  &.p-dialog {
    background-color: rgb(17, 16, 29);
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px 0px;
    padding: 0px;
    width: fit-content;
    overflow: hidden;
    align-self: center;
    min-width: 25rem;
    max-height: 80vh;
    min-height: auto;
    display: flex;
    border-radius: 12px;
    ${media.largeMobile.max(`
    min-width: 80%;
    padding: 0.8rem;
    &.p-dialog .p-dialog-header { 
      padding: 0rem 0.5rem 1.5rem 0.5rem;
    }
    &.p-dialog .p-dialog-content { 
      padding: 0rem;
    }
    `)}
  }
  &.p-dialog-mask {
    background-color: transparent !important;
    backdrop-filter: blur(1.5px) !important;
  }

  &.p-dialog-mask &.p-component-overlay {
    background-color: transparent !important;
    backdrop-filter: blur(1.5px) !important;
  }

  background: #1C1A3;
  width: 38vw;
  border-radius: 2.0488px;
  &.p-dialog .p-dialog-content {
    background: transparent;
    border-radius: 2.0488px;
    padding: ${({ padding }) =>
    padding ? "1rem 0.5rem 0rem 0.5rem" : "0 1.5rem 1rem 1.5rem"};
  }
  &.p-dialog .p-dialog-header {
    background: transparent;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;
    text-transform: capitalize;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: white;
    margin-bottom: -1.5rem;
    padding: 1rem 2rem 2rem 2rem;
  }

  ${media.tablet.max(`
    width: 68vw;
    `)}
  ${media.largeMobile.max(`
    padding: 0.8rem;
    &.p-dialog .p-dialog-header { 
      padding: 0rem 0.5rem 1.5rem 0.5rem;
    }
    &.p-dialog .p-dialog-content { 
      padding: 0rem;
    }
    `)}
`;
export const ChooseDiv = styled.div`
  border: 1px solid;
  border-image-source: linear-gradient(
    114.3deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 48.44%,
    #ffffff 100%
  );
  overflow-y: scroll;
  ${media.largeMobile.max(`
 margin-top: 0rem;
  `)}
`;
export const UpperDiv = styled.div`
  margin-top: 2rem;
  margin-right: 1.5rem;
  ${media.largeMobile.max(`
margin-top: 1.6rem;
 `)}
`;
export const FirstDiv = styled.div`
  background: #070618;
  border-radius: 8px;
  cursor: pointer;
  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
  color: white;
  padding: 1rem;
  &:hover {
    background: #010013;
    cursor: pointer;
  }
  &:active {
    background: #010013;
    cursor: pointer;
  }
`;
export const CircleDiv = styled.div`
  background: rgba(64, 57, 87, 0.28);
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  border: 0px solid;
  margin-left: 0.7rem;
  margin-top: 3.3rem;
  border-image: round;
  cursor: pointer;
  display: flex;
  justify-content: center;
  ${media.tablet.max(`
  margin: 0rem;
  
  `)}
`;
export const AccordianCircleDiv = styled.div`
  background: rgba(64, 57, 87, 0.28);
  border-radius: 50%;
  height: 1.566rem;
  width: 1.6rem;
  border: 0px solid;
  margin-left: 0.7rem;
  margin-top: 3.3rem;
  border-image: round;
  cursor: pointer;
  display: flex;
  justify-content: center;
  ${media.tablet.max(`
  margin: 0rem 0.5rem;
  `)}
`;
export const CircleDivTwo = styled.div`
  // background: rgba(64, 57, 87, 0.28);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  border: 0px solid;

  border-image: round;

  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
export const CircleImg = styled.div`
  // background: #221e40;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  // border: 1px solid;

  border-image: round;
  display: flex;
  justify-content: center;
  ${media.largeMobile.max(`
  width: 2rem;
  height: 2rem;
  
  `)}
`;

export const CircleImgSmall = styled.div`
  // background: #221e40;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  // border: 1px solid;

  border-image: round;
  display: flex;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
export const NetworkHeading = styled.h4`
  font-family: Chillax;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;
  text-transform: capitalize;

  color: white;

  ${media.tablet.max(`
    font-size: 6px; 
    `)}
`;
export const ChainHeading = styled.h4`
  ${media.largeMobile.max(`
font-size: 9px; 
`)}
`;
export const ChainSubHeading = styled.h6`
  ${media.largeMobile.max(`
font-size: 6px; 
`)}
`;
export const NetworkIcon = styled.img`
  border-radius: 6px;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  ${media.largeMobile.max(`
   
    margin-right: 0.5rem;
  `)}
`;

export const Paragraph = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.7rem;
  font-weight: 400;
  font-family: "Poppins";
  width: 30rem;
  color: white;
  ${media.largeMobile.max(`
width: 19rem;
`)}
`;
export const NetworkTitle = styled.span`
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background: linear-gradient(
    73.6deg,
    #91dcf0 6.19%,
    #25b0d7 25.05%,
    #fa8ffa 57.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.largeMobile.max(`
  font-size: 8px;
      `)}
`;

export const InputTextSearch = styled(InputText)`
  &.p-inputtext {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    background: transparent;
    position: static;
    width: 100%;
    height: 56px;
    left: 0px;
    top: 32px;
    color: white;
    /* Gray/18 */

    border: 1px solid rgb(64, 68, 79);
    box-sizing: border-box;
    border-radius: 14px;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
    padding-left: 3rem;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent !important;
    border-radius: 14px;
    outline: 0;
    outline-offset: 0;
  }
  &:hover {
    box-shadow: 0 0 0 0.2rem transparent !important;
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent !important;
    border-radius: 14px;
    outline: 0;
    outline-offset: 0;
  }

  &:focus-visible {
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent;
    border-radius: 14px;
    outline: 0;
  }
  ${media.largeMobile.max(`
  &.p-inputtext { 
    height: 2.5rem;
  }
  `)}
`;

export const InsideDiv = styled.div`
  ${media.largeMobile.max(`
margin-left: -1rem
`)}
`;

export const FailedPara = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 29px;
  /* or 161% */

  text-align: center;

  /* Pink */
  margin: 1rem 2.5rem;
  color: #ff39f0;
`;

export const ApproveImg = styled.img`
  position: absolute;
  top: calc(50% - 8rem);
  left: calc(50% - 5.5rem);
  width: 10rem;
  margin-top: ${(props) => (props.failed ? "-1rem" : "0rem")};
`;

export const FailedImg = styled.img`
  position: absolute;
  top: calc(50% - 8rem);
  left: calc(50% - 5rem);
  width: 10rem;
  margin-top: ${(props) => (props.failed ? "-1rem" : "0rem")};
`;

export const PendingImg = styled.img`
  top: calc(50% - 6rem);
  left: calc(50% - 5.5rem);
  width: 9rem;
  height: 8rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const ImgDiv = styled.div`
  // background: linear-gradient(
  //     318.86deg,
  //     #5902d2 0%,
  //     rgba(133, 66, 244, 0) 53.86%
  //   ),
  //   radial-gradient(
  //     58.26% 58.26% at 0% 103.31%,
  //     #19d2ff 26.56%,
  //     rgba(16, 231, 251, 0) 100%
  //   ),
  //   radial-gradient(
  //     67.56% 67.56% at 100% 0%,
  //     #ffccfb 0%,
  //     #ff39f0 45.31%,
  //     rgba(238, 65, 255, 0) 100%
  //   ),
  //   radial-gradient(
  //     83.68% 83.68% at 0% 0%,
  //     #5902d2 0%,
  //     rgba(133, 66, 244, 0) 95.83%
  //   );
  filter: blur(79px);
  transform: rotate(-176.18deg);
  width: 10rem;
  height: 10rem;
`;
export const LastDiv = styled.div`
  padding-top: 0rem;
`;
export const PoolInfoItems = styled.div`
  margin-bottom: 0.5rem;
  text-align: left;
  display: ;
`;
export const BottomText = styled.span`
  font-style: normal;
  font-weight: normal;
  line-height: 29px;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Gray/8 */

  color: #a6a0bb;
`;
export const BottomNumber = styled.span`
  float: right;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* White */

  color: #ffffff;
  ${media.tablet.max(`
  
  font-size: 14px;
  `)}
`;
export const BottomNumberSmall = styled.span`
  float: right;

  display: flex;
  ${media.tablet.max(`
  
  font-size: 14px;
  `)}
`;
export const MaxButton = styled.button`
  border: 1px solid;

  border-image-source: linear-gradient(
    114.3deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 48.44%,
    rgba(255, 255, 255, 0.4) 100%
  );
  background: linear-gradient(
      318.86deg,
      #19d2ff 0%,
      rgba(25, 210, 255, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #19d2ff 0%,
      rgba(25, 210, 255, 0) 95.83%
    );
  border-radius: 8px;
  color: white;
  font-family: Chillax !important;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;
  min-width: 3rem;
  /* Color / White */

  color: #ffffff;
`;
export const SymbolLogo = styled.img`
  margin-top: calc(18%);
  margin-left: calc(31%);
`;
export const ExternalImg = styled.img`
  margin-left: 0.2rem;
`;
export const MaxNumber = styled.h2`
  margin: 0rem 1rem;
  margin-left: -3rem;
  margin-top: 1.7rem;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
  line-height: 14px;
  text-transform: capitalize;
  color: #ff9a36;
  font-family: "Ranade-Semibold" !important;
  &:hover {
    color: white;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
`;
export const Input = styled.input`
  margin-top: 1rem;
  padding-top: 4px;
  border-color: #7151e1;
  font-size: 1rem;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid;
  color: white;
  font-weight: 600;
  width: 100%;
  &:placeholder {
    color: white;
  }
`;
export const LinkSpan = styled.span`
  background-image: linear-gradient(275.86deg, #5ea0fd 19.06%, #0b6ffb 135.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-decoration: underline;
  border-bottom: 1px solid #0b6ffb;
  cursor: pointer;
`;

export const MainHeading = styled.p`
  font-size: 36px;
  position: fixed;
  opacity: 0.7;
  width: 35rem;
  font-family: "Chillax-Medium" !important;
  font-weight: 400;
  ${media.tablet.max(`
    font-size: 18px;
    width: auto;
    margin-top: 5rem;
    position: inherit;
    margin-bottom: -6rem;
    `)}
  ${media.largeMobile.max(`
    font-size: 18px;
    width: auto;
    margin-top: 4rem;
    position: inherit;
    margin-bottom: -6rem;
    `)}
`;

export const HistoryMessages = styled(Messages)`
  background: #b3e5fc;
  border: 0px solid #0891cf;
  border-width: 0 0 0 0px;
  color: white;
  margin-top: 5rem;
  background: linear-gradient(
    275.86deg,
    rgba(94, 160, 253, 0.16) 19.06%,
    rgba(11, 111, 251, 0.16) 135.34%
  );
  width: 40rem;
  &.p-message-info.p-message-info {
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  width: 10rem;
  height: auto;
  margin-top: 1.6rem;
  margin-left: 1rem;
  ${media.tablet.max(`
         width: auto;
    height: 1.5rem;
    margin-left: 1rem;
    margin-top: 2rem;
    padding: 0rem;
    `)};
  ${media.largeMobile.max(`
        width: auto;
    height: 1.2rem;
    margin-left: 0.5rem;
    margin-top: 2rem;
    padding: 0rem;
    `)};
  ${media.mobile.max(`
        width: auto;
    height: 1.2rem;
    margin-top: 2rem;
    padding: 0rem;
    `)};
`;
export const AddressHeading = styled.h6`
  color: white;
  cursor: pointer;
  margin: 0;
  margin-top: 0.5rem;
`;

export const TransactionHeading = styled.span`
  cursor: pointer;
  &:hover {
    color: white;
  }
`;
export const SocialSpan = styled.h6`
  color: #f8f8fe;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin-left: 0.4rem;
`;
export const LineSpan = styled.span`
  background-color: #f8f8fe;
  width: 2rem;
  height: 0.01rem;
  margin-top: 2.75rem;
  margin-left: 1rem;
`;
export const NetworkContainer = styled.div`
  position: relative;
  ${media.tablet.max(`
   display: flex;
   margin-right: 0rem;
   margin-top: -0.2rem;
    `)};

  ${media.largeMobile.max(`
   display: flex;
   margin-right: 0rem;
   margin-top: 1rem;
    `)};
`;

export const NetworkButton = styled.button`
  background: rgba(106, 121, 159, 0.18);
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  border-radius: 5px;
  border: 0px solid #4a4a4e;
  color: white;
  cursor: pointer;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;

  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  margin-top: 1.7rem;
  border-radius: 25px;
  margin-right: 1rem;
  padding: 0.5rem 16px 0.5rem 8px;
  display: flex;
  align-items: center;
  ${media.tablet.max(`
  font-size: 7px;
  margin: 0;
  padding: 15px;
  margin-right: 2.5rem;
  border: 0;
  margin-top: 2rem;
  height: 2.4rem;
  background-color: rgba(106,121,159,0.18);
  border: 0px solid #4a4a4e;
    
    `)};
  ${media.largeMobile.max(`
  font-size: 7px;
  margin: 0;
  padding: 7px;
  margin-right: 2.5rem;
  border: 0;
  margin-top: 0.7rem;
  height: 2rem;
  background-color: rgba(106,121,159,0.18);
  border: 0px solid #4a4a4e;
    `)};
`;

export const NetworkItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  ${media.largeMobile.max(`
  margin-right: 0.3rem;
`)};
`;

export const DropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
`;
export const FooterFontIcon = styled(FontAwesomeIcon)`
  margin: 0.43rem;
  color: #696694;
  ${media.largeMobile.max(`
margin: 0.23rem;

`)};
`;
export const DropdownBox = styled.div`
  position: absolute;
  right: 30px;
  top: 4.7em;
  width: 225px;
  padding: 3px 0px;
  display: flex;
  flex-direction: column;
  background: rgba(54, 54, 67, 0.23);
  color: white;
  border-radius: 6px;
  opacity: 1.6;
  z-index: 1000;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  ${media.tablet.max(`
  margin-top: 0.5rem;
  position: absolute;
  right: 30px;
  top: 4.7em;
  width: 225px;
  padding: 3px 0px;
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
  border-radius: 6px;
  opacity: 1.6;
  z-index: 1000;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  `)};
  ${media.largeMobile.max(`
  position: absolute;
  right: 50px;
  top: 4em;
  width: 183px;
  padding: 3px 0px;
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
  border-radius: 6px;
  opacity: 1.6;
  z-index: 1000;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  `)};
`;

export const DropdownHook = styled.div`
  display: block;
  z-index: -1;
  text-align: center;
  position: absolute;
  top: -7px;
  left: 195px;
  height: 18px;
  width: 18px;
  transform: rotate(45deg);
  background: rgba(54, 54, 67, 0.23);
  border-radius: 2px;
  ${media.tablet.max(`
  background: black;
  `)};
  ${media.largeMobile.max(`
  background: black;
  top: -18px;
  left: 160px;
  `)};
`;

export const DropdownItem = styled.div`
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #05040f;
  }
`;

export const TradeMain = styled.div`
  background: url(${TradeMainImage});
  background-color: black;
  width: auto;
  border: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
export const CenterDiv = styled.div`
  text-align: center;
  justify-content: center;
  width: 30rem;
`;
export const CenterHeading = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  /* or 48px */

  text-align: center;

  /* Base/Grey 0 */

  color: #ffffff;
  animation: flipInX; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`;
export const CenterSubHeading = styled.h5`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  /* or 229% */

  text-align: center;

  color: #a6a0bb;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`;
export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 3rem;
  animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`;
export const TradeButton = styled.button`
  background: linear-gradient(
      318.86deg,
      #563bd3 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #6fe38f 0%,
      rgba(133, 66, 244, 0.2) 95.83%
    );
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 0.7rem;

  cursor: pointer;
  &:hover {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`;
export const TradeButtonTwo = styled.button`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  margin-right: 2rem;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-top: 0.7rem;

  cursor: pointer;
  &:hover {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s;
  }
`;
export const PoolDiv = styled.div`
  background: rgba(13, 13, 30, 0.4);
  margin-top: 2rem;
  padding: 4rem 7rem;
  ${media.tablet.max(`
padding:3rem 0rem 0rem  0rem;
background: transparent;
`)}
`;

export const TableDiv = styled.div`
  background: rgba(27, 26, 46, 0.4);
  border-radius: 12px;
  padding: 2rem;
  margin-top: 2rem;
  min-height: 25rem;
  max-height: 30rem;
  overflow-y: scroll;
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.8s;
  animate-delay: 0.5s;
  ${media.largeMobile.max(`
display: none;`)}
`;
export const AccordianDiv = styled.div`
  display: none;
  ${media.largeMobile.max(`
  display: inherit;
  background: #060518;
  border-radius: 0px;
  padding: 3rem 1rem;
  margin-top: 2rem;
  height: 600px;
  overflow-y: scroll;
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.8s;
  animate-delay: 0.5s;
  overflow-y: scroll; 
  `)}
`;
export const StyledAccordianTab = styled(AccordionTab)`
  background: #0e0d20;
  &.p-accordion .p-accordion-header {
    border: 0px;
    background: #0e0d20;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }
  &.p-accordion-header-text {
    display: flex;
  }
`;
export const StyledAccordian = styled(Accordion)`
  &.p-accordion-header-text {
    display: flex;
  }
  &.p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    margin-right: 0.5rem;
    margin-top: -8px;
    position: absolute;
    right: 0.5em;
    top: 50%;
    color: #8d9fb3;
    font-size: 10px;
  }
  &.p-accordion .p-accordion-header .p-accordion-header-link {
    background: #0e0d20;
    border: 0px;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }
  &.p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    background: #0e0d20;
    border: 0px;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }
  &.p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background: #0e0d20;
    border: 0px;
    outline: 0;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }
  &.p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #0e0d20;
    border: 0px;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }
  &.p-accordion .p-accordion-tab {
    margin-bottom: 1rem;
    background: #0e0d20;
    box-shadow: 0 0 0 0rem #a6d5fa;
  }
  &.p-accordion .p-accordion-header {
    border: 0px;
    background: #0e0d20;
    box-shadow: 0 0 0 0rem #a6d5fa;
    &.p-accordion-header-text {
      display: flex;
    }
  }

  &.p-accordion .p-accordion-content {
    background: #0e0d20;
    border: 0px;
  }
`;

export const FirstButton = styled.button`
  background: #111025;
  border: 0;

  padding: 0rem 3rem;
  border-radius: 100px;

  height: 3rem;
  margin-right: -2rem;
  cursor: pointer;
`;
export const SecondButton = styled.button`
  background: #3b3c4e;
  border: 0;

  padding: 0rem 3rem;
  border-radius: 100px;

  height: 3rem;
  margin-right: 3rem;
  color: white;
  cursor: pointer;
`;
export const RemoveImg = styled.img``;
export const FontAwesomeIconSearch = styled.button`
  color: rgba(255, 255, 255, 0.6);
`;
export const SortButton = styled.button`
  border: 0px;
  background: #3b3c4e;
  border-radius: 100px;
  color: white;
  padding: 0.5rem 1.5rem 0.5rem 1.2rem;
  height: 3rem;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;

  color: #a6a0bb;
`;

export const Table = styled.table`
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`;
export const TableHeading = styled.th`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 185% */
  color: #a6a0bb;
  text-align: left;
`;
export const NameHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 185% */
  color: #a6a0bb;
  text-align: left;
  display: flex;
`;
export const NameContent = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10.5px;
  line-height: 24px;
  /* identical to box height, or 229% */

  color: #a6a0bb;
  /* identical to box height, or 133% */

  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  text-align: right;
  flex: auto;
  /* White */
  /* identical to box height, or 171% */
  padding-top: 0.2rem;
`;
export const TableRow = styled.tr``;
export const TableData = styled.td`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;

  /* White */

  color: #ffffff;
  /* identical to box height, or 171% */
  padding-right: 5rem;
  padding-top: 2rem;
  ${media.tablet.max(`
font-size: 9px;
  `)}
`;
export const SecondTableData = styled.td`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;

  /* White */

  color: #ffffff;
  /* identical to box height, or 171% */
  padding-right: 5rem;
  padding-top: 2rem;
  ${media.tablet.max(`
font-size: 9px;
// padding-right: 1rem;
  `)}
`;
export const FirstTableData = styled.td`
  margin-right: 3rem;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-top: 2rem;
  /* White */

  color: #ffffff;
  ${media.tablet.max(`
font-size: 9px;
  `)}
`;

export const TableLogo = styled.img`
  margin: 3px;
`;
export const AccordianLogo = styled.img`
  margin: 1px 1.7px;
`;
export const HistoryHeading = styled.h3`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */
  margin-top: 6rem;
  font-feature-settings: "pnum" on, "lnum" on;

  /* White */
  margin-left: 7rem;
  color: #ffffff;
  ${media.tablet.max(`
margin-left: 1rem;  `)}
`;
export const TradeLogodiv = styled.div`
  width: 32px;
  height: 32px;
  background: #403957;
  border-radius: 40px;
`;
export const AccordianLogodiv = styled.div`
  width: 28px;
  height: 28px;
  background: #403957;
  border-radius: 40px;
`;
export const SourceLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  margin-top: -1rem;
  margin-left: 5rem;
  display: flex;
  font-family: "Poppins" !important;
  background: linear-gradient(92.19deg, #65cae6 27%, #0485cd 86.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const AccordianSourceLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin: 0;
  display: flex;
  font-family: "Poppins" !important;
  background: linear-gradient(92.19deg, #65cae6 27%, #0485cd 86.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const AccordianLinkTextSource = styled.span`
background: linear-gradient(92.19deg, #65cae6 27%, #0485cd 86.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const DestinationLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  margin-top: 0rem;
  display: flex;
  font-family: "Poppins" !important;
  background: linear-gradient(91.22deg, #b3a7ff 12.97%, #ff75ff 83.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const AccordianDestinationLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-top: 0rem;
  display: flex;
  font-family: "Poppins" !important;
  background: linear-gradient(91.22deg, #b3a7ff 12.97%, #ff75ff 83.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const AccordianLinkTextDestination = styled.span`
  background: linear-gradient(91.22deg, #b3a7ff 12.97%, #ff75ff 83.82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const TimeHeading = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 12px;
  /* identical to box height, or 109% */
  margin-top: 4px;
  align-items: center;

  color: #a6a0bb;
`;
export const PendingText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  color: #ff9a36;
`;
export const CompleteText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  color: #19d2ff;
`;
export const CancelText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  color: #ff39f0;
`;
export const PendingButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  border: 0px solid transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 218% */

  font-feature-settings: "pnum" on, "lnum" on;
  padding: 0.5rem 1.5rem;
  min-width: 7rem;
  color: #ff9a36;
`;
export const CompleteButton = styled.button`
  background: rgba(149, 234, 255, 0.16);
  border-radius: 100px;
  border: 0px solid transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 218% */

  font-feature-settings: "pnum" on, "lnum" on;
  padding: 0.5rem 1.5rem;
  min-width: 7rem;
  color: #19d2ff;
`;
export const CancelButton = styled.button`
  background: rgba(255, 137, 246, 0.1);
  border-radius: 100px;
  border: 0px solid transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 218% */

  font-feature-settings: "pnum" on, "lnum" on;
  padding: 0.5rem 1.5rem;
  min-width: 7rem;
  color: #ff39f0;
`;
export const HistoryButton = styled.button`
  background: linear-gradient(
    275.86deg,
    rgba(94, 160, 253, 0.16) 19.06%,
    rgba(11, 111, 251, 0.16) 135.34%
  );
  border-radius: 32px;
  border: 0px solid transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  /* identical to box height, or 218% */
  cursor: pointer;
  font-feature-settings: "pnum" on, "lnum" on;
  padding: 0rem 1rem;
  color: #667fc8;
  height: 30px;
  margin-left: 2rem;
  margin-top: 0.5rem;
  &:hover {
    background: linear-gradient(
      275.86deg,
      rgba(94, 160, 253, 0.3) 19.06%,
      rgba(11, 111, 251, 0.3) 135.34%
    );
    color: #9fb6f7;
  }
  ${media.tablet.max(`
margin-top: 0rem;    `)}
`;
export const NameSpan = styled.span`
  margin-left: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;

  /* White */

  color: #ffffff;
  ${media.tablet.max(`
font-size: 9px;
  `)}
`;
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

export const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

export const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid #40444f;
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`;

export const AccountGroupingRow = styled.div`
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: rgb(255, 255, 255);
  display: flex;
  div {
    align-items: center;
  }
`;

export const AccountSection = styled.div`
  background-color: transparent;
  padding: 0rem 1rem;
  ${media.tablet.max(`
  padding: 0rem 1rem 1.5rem 1rem;
  `)};
`;

export const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`;

export const LowerSection = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 400;
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-family: "Inter var", sans-serif !important;
  }
`;

export const TextModalSubHeading = styled.p`
  font-size: 0.825rem;
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: rgb(108, 114, 132);
  color: #6c7284;
  text-decoration: none;
  display: flex;
`;

export const AddressLink = styled.a`
  font-size: 0.825rem;
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: rgb(108, 114, 132);
  color: #6c7284;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  :hover {
    color: #c3c5cb;
  }
`;
export const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: #6c7284;
`;

export const IconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + "px" : "32px")};
    width: ${({ size }) => (size ? size + "px" : "32px")};
  }
  ${media.tablet.max(`
  align-items: flex-end;
  `)};
`;

export const TransactionListWrapper = styled.div``;
const Base = styled(RebassButton)`
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`;
const ButtonSecondary = styled(Base)`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 600;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  cursor: pointer;
  ${media.tablet.max(`
  font-size: 10px;
  `)};
`;

export const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const MainWalletAction = styled(WalletAction)``;

export const StyledMenuIcon = styled(MenuIcon)`
  margin-top: 0.2rem;
  path {
    stroke: rgb(255, 255, 255);
  }
`;

export const AccountControl = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const StyledSVG = styled.svg`
  animation: 2s ${rotate} linear infinite;
  height: 16px;
  width: 16px;
  path {
    stroke: #42a0bb;
  }
`;