import React from "react";
import { TableHeading, TableRow } from "../index";

function TableHeader() {
  return (
    <TableRow>
      <TableHeading>Source Chain</TableHeading>
      <TableHeading></TableHeading>
      <TableHeading>Destination Chain</TableHeading>
      <TableHeading>Amount</TableHeading>
      <TableHeading>Fee</TableHeading>
      <TableHeading>Date & Time</TableHeading>
      <TableHeading style={{ textAlign: "center" }}>Status</TableHeading>
    </TableRow>
  );
}

export default TableHeader;
