import { abis, addresses } from "@project/contracts";
import * as Web3 from "web3";

export const getDemodyFiERC20Contract = (provider, address) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(abis.iDemodyFiERC20.abi, address);
  return contract;
};

export const getERC20SContract = (provider, address) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(abis.iERC20S, address);
  return contract;
};

export const getPSBridgeERC20 = (provider) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(
    abis.iPSBridgeERC20,
    addresses.psBridgeErc20s[provider.chainId]
  );
  return contract;
};

export const getPSBridgeERC20Chain2 = (provider, chainId) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(
    abis.iPSBridgeERC20,
    addresses.psBridgeErc20s[chainId]
  );
  return contract;
};