import Jazzicon from 'jazzicon';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Context as WalletContext } from "../contexts/WalletContext.js";

const StyledIdenticonContainer = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 1.125rem;
  margin-left: 5px;
`

export default function Identicon() {
    const ref = useRef()

    const { provider } = useContext(WalletContext);

    useEffect(() => {
        if (provider && ref.current) {
            ref.current.innerHTML = ''
            ref.current.appendChild(Jazzicon(16, (parseInt(provider.selectedAddress.slice(2, 10), 16) ?? 0)))
        }
    }, [provider])

    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    return <StyledIdenticonContainer ref={ref} />
}
