import React from "react";
import { ASSETS } from "../../constants";
import {
  ChainHeading, ChainSubHeading, ChooseDiv,
  CircleImg,
  DialogDiv,
  FirstDiv,
  InputTextSearch,
  InsideDiv, UpperDiv
} from "../index";
import "./styles.scss";


const AssetDialog = ({
  displayBasic2,
  renderHeaderTwo,
  onHide,
  value3,
  setValue3,
  setSendToken,
}) => {

  return (
    <DialogDiv
      visible={displayBasic2}
      header={renderHeaderTwo("displayBasic2")}
      onHide={() => onHide("displayBasic2")}
    >
      {" "}
      <span className="p-input-icon-left" style={{ width: "100%" }}>
        <i style={{ color: " white", left: "1rem" }} className="pi pi-search" />
        <InputTextSearch
          value={value3}
          onChange={(e) => setValue3(e.target.value)}
          placeholder="Search"
          style={{ paddingLeft: "3rem" }}
        />
      </span>
      <ChooseDiv>
        {ASSETS.map((asset) => (
          <FirstDiv
            onClick={() => {
              setSendToken(asset);
              onHide("displayBasic2");
            }}
          >
            <InsideDiv
              style={{
                display: "flex",
                marginTop: "-2rem",
                marginBottom: "-1.5rem",
              }}
            >
              <UpperDiv>
                <CircleImg>
                  <img
                    width="auto"
                    height="auto"
                    src={asset.image}
                    alt="logo"
                    className="dropdown-img"
                  />
                </CircleImg>
              </UpperDiv>
              <div>
                <ChainHeading
                  style={{
                    marginBottom: "0",
                    marginTop: "2rem",
                    textAlign: "left",
                  }}
                >
                  {asset.symbol}
                </ChainHeading>
                <ChainSubHeading style={{ marginTop: 7 }}>
                  {asset.label}
                </ChainSubHeading>
              </div>
            </InsideDiv>
          </FirstDiv>
        ))}
      </ChooseDiv>
    </DialogDiv>
  );
};

export default AssetDialog;
