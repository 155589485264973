import Bsc from "../assets/bridge.svg";
import Default from "../assets/default3.png";
import Dmod from "../assets/dmod.png";
import DmodSelect from "../assets/dmodselect.png";
import Eth from "../assets/ethereum.svg";
import Moon from "../assets/moonbeam.svg";
import Poly from "../assets/polygon.svg";
import Usdc from "../assets/usdc.png";
import Usdt from "../assets/usdt.png";

export const DEFAULT_CHAIN = {
  name: "Select Network",
  image: Default,
  chainId: "none",
};

export const CHAINS = {
  // BSC: { name: "Binance Smart Chain", image: Bsc, symbol: "BSC" },
  // Ethereum: { name: "Ethereum Network", image: Eth, symbol: "ETH" },
  Moonbeam: {
    name: "Moonbeam Network",
    shortName: "Moonbeam",
    image: Moon,
    tokenName: "Glimmer Token",
    symbol: "GLMR",
    chainId: "0x504",
    bridgeId: 2,
    explorer: "https://moonscan.io/",
    rpcUrl: "https://moonbeam.api.onfinality.io/public",
    wssUrl: "wss://moonbeam.api.onfinality.io/public-ws",
    subgraph: "https://api.thegraph.com/subgraphs/name/demodyfi/bridge-moonbeam"
  },
  Polygon: {
    name: "Polygon Network",
    shortName: "Polygon",
    image: Poly,
    tokenName: "MATIC Token",
    symbol: "MATIC",
    chainId: "0x89",
    bridgeId: 1,
    explorer: "https://polygonscan.com/",
    rpcUrl: "https://speedy-nodes-nyc.moralis.io/e8eb263a007343c95bde25a2/polygon/mainnet",
    wssUrl: "wss://speedy-nodes-nyc.moralis.io/e8eb263a007343c95bde25a2/polygon/mainnet/ws",
    subgraph: "https://api.thegraph.com/subgraphs/name/demodyfi/bridge-polygon"
  },
  Ethereum: {
    name: "Ethereum Mainnet",
    shortName: "Ethereum",
    image: Eth,
    tokenName: "Ethereum Token",
    symbol: "ETH",
    chainId: "0x1",
    bridgeId: 0,
    explorer: "https://etherscan.io/",
    rpcUrl: "https://speedy-nodes-nyc.moralis.io/e8eb263a007343c95bde25a2/eth/mainnet",
    wssUrl: "wss://speedy-nodes-nyc.moralis.io/e8eb263a007343c95bde25a2/eth/mainnet/ws",
    subgraph: "https://api.thegraph.com/subgraphs/name/demodyfi/bridge-ethereum"
  },
  BSCMAINNET: {
    name: "Binance Smart Chain",
    shortName: "BSC",
    image: Bsc,
    tokenName: "BNB",
    symbol: "BNB",
    chainId: "0x38",
    bridgeId: 3,
    explorer: "https://bscscan.com/", // Always add trailing slash
    rpcUrl: "https://speedy-nodes-nyc.moralis.io/e8eb263a007343c95bde25a2/bsc/mainnet",
    wssUrl: "wss://speedy-nodes-nyc.moralis.io/e8eb263a007343c95bde25a2/bsc/mainnet/ws",
    subgraph: "https://api.thegraph.com/subgraphs/name/demodyfi/bridge-bsc"
  },
};

export const DEFAULT_ASSET = {
  label: "Choose a Token",
  symbol: "Select Token",
  address: "0x0000000000000000000000000000000000000000",
  image: Eth,
  decimals: 18
};

export const ASSETS = [
  {
    label: "Demodyfi Token",
    symbol: "DMOD",
    decimals: {
      [CHAINS.Moonbeam.chainId]: 18,
      [CHAINS.Polygon.chainId]: 18,
      [CHAINS.Ethereum.chainId]: 18,
      [CHAINS.BSCMAINNET.chainId]: 18,
    },
    address: {
      [CHAINS.Moonbeam.chainId]: "0x73Ff1cc774883e22bf6ea998B55B5E7211e08B12",
      [CHAINS.Polygon.chainId]: "0xD7AedeDb52Fa863A70A877E3a0cf40D40283016c",
      [CHAINS.Ethereum.chainId]: "0x5f6c5c2fb289db2228d159c69621215e354218d7",
      [CHAINS.BSCMAINNET.chainId]: "0x002D8563759f5e1EAf8784181F3973288F6856e4",
    },
    image: DmodSelect,
    resourceId:
      "0x000000000000000000000000EA674fdDe714fd979de3EdF0F56AA9716B898ec8",
  },
  {
    label: "Tether USD",
    symbol: "USDT",
    decimals: {
      [CHAINS.Moonbeam.chainId]: 6,
      [CHAINS.Polygon.chainId]: 6,
      [CHAINS.Ethereum.chainId]: 6,
      [CHAINS.BSCMAINNET.chainId]: 18,
    },
    address: {
      [CHAINS.Moonbeam.chainId]: "0x18a389d5af5B5C665Fbb9A091870FB45f428cC22",
      [CHAINS.Polygon.chainId]: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      [CHAINS.Ethereum.chainId]: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      [CHAINS.BSCMAINNET.chainId]: "0x55d398326f99059ff775485246999027b3197955",
    },
    image: Usdt,
    resourceId:
      "0x000000000000000000000000EA674fdDe714fd979de3EdF0F56AA9726B898ec8",
  },
  {
    label: "USD Coin",
    symbol: "USDC",
    decimals: {
      [CHAINS.Moonbeam.chainId]: 6,
      [CHAINS.Polygon.chainId]: 6,
      [CHAINS.Ethereum.chainId]: 6,
      [CHAINS.BSCMAINNET.chainId]: 18,
    },
    address: {
      [CHAINS.Moonbeam.chainId]: "0x70d07DA8E7E9d57cF98862D37fCfdA37F4FB0BAB",
      [CHAINS.Polygon.chainId]: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      [CHAINS.Ethereum.chainId]: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      [CHAINS.BSCMAINNET.chainId]: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    image: Usdc,
    resourceId:
      "0x000000000000000000000000EA674fdDe714fd979de3EdF0F56AA9736B898ec8",
  },
];

export const DMOD = {
  label: "Demodyfi Token",
  symbol: "DMOD",
  address: {
    [CHAINS.Moonbeam.chainId]: "0x73Ff1cc774883e22bf6ea998B55B5E7211e08B12",
    [CHAINS.Polygon.chainId]: "0xD7AedeDb52Fa863A70A877E3a0cf40D40283016c",
    [CHAINS.Ethereum.chainId]: "0x5f6c5c2fb289db2228d159c69621215e354218d7",
    [CHAINS.BSCMAINNET.chainId]: "0x002D8563759f5e1EAf8784181F3973288F6856e4",
  },
  image: Dmod,
  resourceId:
    "0x000000000000000000000000EA674fdDe714fd979de3EdF0F56AA9716B898ec8",
}

export const MINTER_ROLE = "0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6"

export const RELAYERS_VOTES_REQUIRED = 2