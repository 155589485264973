import React, { useContext, useEffect, useState } from "react";
import { Check, Copy } from 'react-feather';
import { Context as WalletContext } from "../../contexts/WalletContext";
import { formatAddress, getCurrentChain } from "../../utils/utilsFunc";
import Identicon from "../Identicon";
import { AccountControl, AccountGroupingRow, AccountSection, AddressLink, DialogDiv, InfoCard, LinkIcon, TextModalSubHeading, UpperSection, WalletAction, YourAccount } from "../index";
import "./styles.scss";


const AccountDialog = ({
  display,
  displayBasic,
  renderHeader,
  onHide,
}) => {
  const { provider, logoutOfWeb3Modal } = useContext(WalletContext);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }, [copied])

  return (
    <DialogDiv
      padding height
      visible={displayBasic}
      header={renderHeader(display)}
      onHide={() => onHide(display)}
      style={{ paddingBotton: "0rem" }}
    >
      <UpperSection>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                <TextModalSubHeading>Connected With MetaMask</TextModalSubHeading>
                <div>
                  <WalletAction
                    style={{ fontSize: '.825rem', fontWeight: 400 }}
                    onClick={() => {
                      logoutOfWeb3Modal()
                      onHide("displayBasic")
                    }}
                  >
                    Disconnect
                  </WalletAction>
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow style={{ justifyContent: 'flex-start' }}>

                <>
                  <AccountControl style={{ justifyContent: 'flex-start' }}>
                    <div style={{ marginTop: "-0.8rem", marginRight: "0.5rem" }}>
                      <Identicon />
                    </div>
                    <div><h4>{provider ? formatAddress(provider.selectedAddress) : null}</h4></div>
                  </AccountControl>
                </>
              </AccountGroupingRow>
              <AccountGroupingRow>
                <AccountControl>
                  <AddressLink onClick={() => setCopied(true)}>
                    {
                      copied ? (
                        <Check size={'16'} />
                      ) : (<Copy size={'16'} />)
                    }
                    <span style={{ marginLeft: '4px' }}>Copy Address</span>
                  </AddressLink>
                  <AddressLink
                    target="_blank"
                    href={provider ? (getCurrentChain(provider.chainId)?.explorer + "address/" + provider.selectedAddress) : null}
                  >
                    <LinkIcon size={16} />
                    <span style={{ marginLeft: '4px' }}>View on Explorer</span>
                  </AddressLink>
                </AccountControl>
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
    </DialogDiv >
  );
};

export default AccountDialog;
