import { faArrowRight, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ethers } from "ethers";
import React from "react";
import external from "../../assets/external.svg";
import {
  getChainByBridgeId,
  getDateString,
  getTokenByResourceId
} from "../../utils/utilsFunc";
import {
  CancelButton,
  CircleDiv,
  CompleteButton,
  DestinationLink,
  ExternalImg,
  FirstTableData,
  NameSpan,
  PendingButton,
  SecondTableData,
  SourceLink,
  TableData,
  TableLogo,
  TableRow,
  TimeHeading,
  TradeLogodiv
} from "../index";

function DataRow({ item }) {

  return (
    <TableRow>
      <FirstTableData style={{ display: "flex" }}>
        <TradeLogodiv>
          <TableLogo
            width="auto"
            height="auto"
            src={getChainByBridgeId(item.sourceChainID).image}
          />
        </TradeLogodiv>
        <TradeLogodiv>
          <TableLogo
            width="auto"
            height="auto"
            src={getChainByBridgeId(item.destinationChainID).image}
          />
        </TradeLogodiv>
        <NameSpan style={{ marginTop: "-1rem" }}>
          {getChainByBridgeId(item.sourceChainID).shortName}
        </NameSpan>
        <br />
      </FirstTableData>
      <SourceLink
        href={getChainByBridgeId(item.sourceChainID).explorer + "tx/" + item.id}
        target="_blank"
      >
        Source Transaction
        <div style={{ marginTop: "0.1rem" }}>
          <ExternalImg src={external} />
        </div>{" "}
      </SourceLink>
      <SecondTableData>
        <CircleDiv style={{ marginTop: "0rem", marginLeft: "3rem" }}>
          <FontAwesomeIcon
            style={{ marginTop: "0.8rem" }}
            icon={faArrowRight}
            color="#a6a0bb"
          />
        </CircleDiv>
      </SecondTableData>
      <SecondTableData style={{ marginTop: "-1rem" }}>
        {getChainByBridgeId(item.destinationChainID).shortName} <br />
        <DestinationLink
          href={
            item.executeProposal
              ? getChainByBridgeId(item.destinationChainID).explorer +
              "tx/" +
              item.executeProposal?.id
              : "#"
          }
          target={item.executeProposal ? "_blank" : ""}
        >
          Destination Transaction
          <div style={{ marginTop: "0.1rem" }}>
            <ExternalImg src={external} />
          </div>{" "}
        </DestinationLink>
      </SecondTableData>
      <TableData style={{ fontWeight: "400" }}>
        {ethers.utils.formatUnits(
          ethers.BigNumber.from(item.amount).toString(),
          getTokenByResourceId(item.resourceID).decimals[getChainByBridgeId(item.sourceChainID).chainId]
        )}{" "}
        {getTokenByResourceId(item.resourceID).symbol}
      </TableData>
      <TableData style={{ fontWeight: "300", color: "#A6A0BB" }}>
        {ethers.utils.formatEther(item.fee)}{" "}
        {getChainByBridgeId(item.sourceChainID).symbol}
      </TableData>
      <SecondTableData style={{ fontWeight: "400" }}>
        {getDateString(item.timestamp).split(",")[0]}
        <br />{" "}
        <TimeHeading>
          <FontAwesomeIcon style={{ marginRight: "0.2rem" }} icon={faClock} />
          {getDateString(item.timestamp).split(",")[1]}
        </TimeHeading>
      </SecondTableData>
      <TableData style={{ paddingRight: 0 }}>
        {item.executeProposal ? (<CompleteButton>Completed</CompleteButton>) : null}
        {!item.executeProposal && !item.voteProposalFailed ? <PendingButton>Pending</PendingButton> : null}
        {item.voteProposalFailed ? <CancelButton>Failed</CancelButton> : null}
      </TableData>
    </TableRow>
  );
}

export default DataRow;
