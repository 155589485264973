import {
  faGithub, faLinkedin, faMedium, faTelegram, faTwitter
} from "@fortawesome/free-brands-svg-icons";
import React, { useEffect, useState } from "react";
import { AccountSection, DialogDiv, FooterFontIcon, FooterIcon, FooterRow, Paragraph, UpperSection, YourAccount } from "../index";
import "./styles.scss";

const AnalyticsDialog = ({
  display,
  displayBasic,
  renderHeader,
  onHide,
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }, [copied])

  return (
    <DialogDiv
      padding height
      visible={displayBasic}
      header={renderHeader(display)}
      onHide={() => onHide(display)}
      style={{ paddingBotton: "0rem" }}
    >
      <UpperSection>
        <AccountSection>
          <YourAccount>
            <div style={{ padding: '0rem 2rem 1rem 2rem', textAlign: 'justify' }}>
              <Paragraph>
                The demodyfi bridge is currenlty new and we're still in the early stages. Once we have enough data to display
                we're going to launch the Analytics. We will announce the news when it's live on all social platform so keep
                an eye out for the news.
                <br />
                <br />
                Please do reach out to us in any of our social platforms if you have any questions in the meanwhile.
                <br />
                <FooterRow style={{ padding: '1rem 0rem' }}>
                  <FooterIcon
                    style={{ marginLeft: '0.5rem' }}
                    onClick={e => window.open('https://github.com/DemodyFI', '_blank', 'noopener noreferrer')}
                  >
                    <FooterFontIcon icon={faGithub} />
                  </FooterIcon>
                  <FooterIcon
                    onClick={e => window.open('https://www.linkedin.com/company/demodyfi/', '_blank', 'noopener noreferrer')}
                  >
                    <FooterFontIcon icon={faLinkedin} />
                  </FooterIcon>
                  <FooterIcon onClick={e => window.open('https://twitter.com/DemodyFi', '_blank', 'noopener noreferrer')}>
                    <FooterFontIcon icon={faTwitter} />
                  </FooterIcon>
                  <FooterIcon onClick={e => window.open('https://t.me/DemodyFI', '_blank', 'noopener noreferrer')}>
                    <FooterFontIcon icon={faTelegram} />
                  </FooterIcon>
                  <FooterIcon onClick={e => window.open('https://demodyfi.medium.com/', '_blank', 'noopener noreferrer')}>
                    <FooterFontIcon icon={faMedium} />
                  </FooterIcon>
                </FooterRow>
              </Paragraph>
            </div>
          </YourAccount>
        </AccountSection>
      </UpperSection>
    </DialogDiv >
  );
};

export default AnalyticsDialog;
