import React, { createContext } from "react";
import useWallet from "../hooks/useWallet";

export const Context = createContext({ provider: null });

export const WalletContext = ({ children }) => {
  const { provider, loadWeb3Modal, logoutOfWeb3Modal } = useWallet();

  return (
    <Context.Provider value={{ provider, loadWeb3Modal, logoutOfWeb3Modal }}>
      {children}
    </Context.Provider>
  );
};

export default WalletContext;
