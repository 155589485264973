// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.

import { CHAINS } from "@project/react-app/src/constants/index.js";

const addresses = {
  ceaErc20: "0xc1C0472c0C80bCcDC7F5D01A376Bd97a734B8815",
  demodyFIFactory: "0x3584446E08A4433f3b57d0D53A4338A282fB570D",
  demodyFIRouter: "0x56a82ED340bB2CFa87777b1C42adE7F687A2196a",
  erc20s: "0x155d5D4A07D1a12008880E3a593f9143d553Ab36",
  psBridgeErc20s: {
    [CHAINS.Moonbeam.chainId]: "0x09cCc114d6dd9896a0f48756b7d8837e59c36D56",
    [CHAINS.Polygon.chainId]: "0x0cdA1224B9ECD774FCe7c6141EF30379efB35e2D",
    [CHAINS.Ethereum.chainId]: "0x180dcF154A082F91b493830051AEA1C740cA1cc2",
    [CHAINS.BSCMAINNET.chainId]: "0xDF0EC644aC6Af4cBdFe4aF0cf15A234Faaf28D60",
  },
  erc20Handler: {
    [CHAINS.Moonbeam.chainId]: "0x180dcF154A082F91b493830051AEA1C740cA1cc2",
    [CHAINS.Polygon.chainId]: "0x549Ae7326Df6036E696ae3Cc3c4c78031A5E918c",
    [CHAINS.Ethereum.chainId]: "0x921c9690e61151721419E05b01dCdfe08Bd1B38C",
    [CHAINS.BSCMAINNET.chainId]: "0xb3Ecc44bebeF021956bba4863F475d28a24238f2",
  },
};

export default addresses;
