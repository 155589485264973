import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useState } from "react";
import Web3 from "web3";
import Approve from "../../assets/Approve.png";
import Cube from "../../assets/Cube.png";
import Failed from "../../assets/Failed.png";
import { Context as WalletContext } from "../../contexts/WalletContext";
import { getCurrentChain } from "../../utils/utilsFunc";
import {
  ApproveImg, DialogDiv, FailedImg, FailedPara, ImgDiv, PendingImg
} from "../index.js";
import "./styles.scss";

const DialogCard = ({
  display,
  tokenValue,
  txStatus,
  txHash,
  transactionReceipt,
  setDisplay,
  transactionType,
  sendToken,
  chain1,
  chain2,
}) => {
  const { provider } = useContext(WalletContext);

  const [transactionDetails, setTransactionDetails] = useState(null);

  const getTransactionDetails = useCallback(async () => {
    if (transactionReceipt) {
      try {
        const web3 = new Web3(provider);
        const txBlock = await web3.eth.getBlock(transactionReceipt.blockNumber);
        setTransactionDetails({
          timestamp: new Date(txBlock.timestamp * 1000).toLocaleString(),
        });
      } catch (error) {
        console.log(
          "%cMyProject%cline:52%cerror",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(227, 160, 93);padding:3px;border-radius:2px",
          error
        );
      }
    }
  }, [transactionReceipt, provider]);

  React.useEffect(() => {
    getTransactionDetails();
  }, [getTransactionDetails]);

  const minifyTxHash = (hash) => {
    return (
      hash.substring(0, 15) +
      "..." +
      hash.substring(hash.length - 16, hash.length - 1)
    );
  };

  return (
    <div className="dialog-demo">
      <div className="card">
        <DialogDiv
          header={
            transactionType === "faucet"
              ? "Getting Tokens from Faucet"
              : null || transactionType === "approve"
                ? "Approving Tokens for Transfer"
                : null || transactionType === "sendTokens"
                  ? "Sending Tokens to the Bridge (Step 1/3)"
                  : null || transactionType === "receiveTokens"
                    ? "Receiving Tokens on " + chain2.shortName + " Bridge (Step 2/3)"
                    : null || transactionType === "minting"
                      ? "Minting Tokens on " + chain2.shortName + " (Step 3/3)"
                      : null
          }
          visible={display}
          onHide={() => setDisplay(false)}
          className="dialog-container"
          style={{
            backgroundColor: "#221E40",
            color: "white",
            borderRadius: "16px",
          }}
        >
          {transactionType === "faucet" ? (
            <>
              <div></div>
              {txStatus === "waiting" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>Waiting For Metamask</div>
                  </div>
                </>
              ) : null}
              {txStatus === "pending" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>
                      <b>Transaction is Pending</b>
                    </div>
                  </div>
                  {txHash ? (
                    <div className="hash-details-container">
                      <div className="date-details">
                        <span>Token Value</span>
                        <br />
                        <span>5 PPC</span>
                      </div>
                      <hr className="hor-divider" />
                      <div className="tx-hash">
                        <div className="hash-details">
                          <span>Tx Hash</span>
                          <br />
                          <span className="tx-hash">
                            <span>{minifyTxHash(txHash)}</span>
                            <span>
                              <a
                                href={
                                  getCurrentChain(provider.chainId).explorer +
                                  "tx/" +
                                  txHash
                                }
                                target="#"
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  className="ext-link-image"
                                />
                              </a>
                            </span>
                          </span>
                        </div>
                      </div>
                      {transactionDetails ? (
                        <>
                          <hr className="hor-divider" />
                          <div className="date-details">
                            <span>Date</span>
                            <br />
                            <span>{transactionDetails.timestamp}</span>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}
              {txStatus === "done" ? (
                <>
                  <div className="progress-container">
                    <div>
                      <ImgDiv />
                      <ApproveImg src={Approve} />
                    </div>
                    <div>
                      <b>Transaction Completed Successfully</b>
                    </div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token Value</span>
                      <br />
                      <span>5 PPC</span>
                    </div>
                    <hr className="hor-divider" />
                    <div className="tx-hash">
                      <div className="hash-details">
                        <span>Tx Hash</span>
                        <br />
                        <span className="tx-hash">
                          <span>
                            {minifyTxHash(transactionReceipt.transactionHash)}
                          </span>
                          <span>
                            <a
                              href={
                                getCurrentChain(provider.chainId).explorer +
                                "tx/" +
                                txHash
                              }
                              target="#"
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                className="ext-link-image"
                              />
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                    {transactionDetails ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="date-details">
                          <span>Date</span>
                          <br />
                          <span>{transactionDetails.timestamp}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "error" ? (
                <>
                  <div className="progress-container">
                    <ImgDiv />
                    <FailedImg failed src={Failed} />
                    <div style={{ marginTop: "2rem" }}>
                      Transaction has Failed
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      <FailedPara>
                        Error Transaction was not mined within 100 blocks,
                        please make sure your transaction was properly sent. Be
                        aware that it might still be mined!
                      </FailedPara>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          {transactionType === "approve" ? (
            <>
              {txStatus === "waiting" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>Waiting For Metamask</div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token</span>
                      <br />
                      <span>
                        {sendToken.symbol}
                      </span>
                    </div>
                  </div>
                </>
              ) : null}
              {txStatus === "pending" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>
                      <b>Transaction is Pending</b>
                    </div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token</span>
                      <br />
                      <span>
                        {sendToken.symbol}
                      </span>
                    </div>
                    {txHash ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="tx-hash">
                          <div className="hash-details">
                            <span>Tx Hash</span>
                            <br />
                            <span className="tx-hash">
                              <span>{minifyTxHash(txHash)}</span>
                              <span>
                                <a
                                  href={chain1.explorer + "tx/" + txHash}
                                  target="#"
                                >
                                  <FontAwesomeIcon
                                    icon={faExternalLinkSquareAlt}
                                    className="ext-link-image"
                                  />
                                </a>
                              </span>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "done" ? (
                <>
                  <div className="progress-container">
                    <div>
                      <ImgDiv />
                      <ApproveImg src={Approve} />
                    </div>
                    <div>
                      <b>Transaction Completed Successfully</b>
                    </div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token </span>
                      <br />
                      <span>
                        {sendToken.symbol}
                      </span>
                    </div>
                    <hr className="hor-divider" />
                    <div className="tx-hash">
                      <div className="hash-details">
                        <span>Tx Hash</span>
                        <br />
                        <span className="tx-hash">
                          <span>
                            {minifyTxHash(transactionReceipt.transactionHash)}
                          </span>
                          <span>
                            <a
                              href={chain1.explorer + "tx/" + txHash}
                              target="#"
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                className="ext-link-image"
                              />
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                    {transactionDetails ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="date-details">
                          <span>Date</span>
                          <br />
                          <span>{transactionDetails.timestamp}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "error" ? (
                <>
                  <div className="progress-container">
                    <ImgDiv />
                    <FailedImg failed src={Failed} />
                    <div style={{ marginTop: "2rem" }}>
                      Transaction has Failed
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      <FailedPara>
                        Error Transaction was not mined within 100 blocks,
                        please make sure your transaction was properly sent. Be
                        aware that it might still be mined!
                      </FailedPara>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          {transactionType === "sendTokens" ? (
            <>
              {txStatus === "waiting" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>Waiting For Metamask</div>
                  </div>
                </>
              ) : null}
              {txStatus === "pending" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>
                      <b>Transaction is Pending</b>
                    </div>
                  </div>
                  {txHash ? (
                    <div className="  hash-details-container">
                      <div className="date-details">
                        <span>Token Value</span>
                        <br />
                        <span>
                          {tokenValue} {sendToken.symbol}
                        </span>
                      </div>
                      <hr className="hor-divider" />
                      <div className="tx-hash">
                        <div className="hash-details">
                          <span>Tx Hash</span>
                          <br />
                          <span className="tx-hash">
                            <span>{minifyTxHash(txHash)}</span>
                            <span>
                              <a
                                href={chain1.explorer + "tx/" + txHash}
                                target="#"
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  className="ext-link-image"
                                />
                              </a>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              {txStatus === "done" ? (
                <>
                  <div className="progress-container">
                    <div>
                      <ImgDiv />
                      <ApproveImg src={Approve} />
                    </div>
                    <div>
                      <b>Transaction Completed Successfully</b>
                    </div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token Value</span>
                      <br />
                      <span>
                        {tokenValue} {sendToken.symbol}
                      </span>
                    </div>
                    <hr className="hor-divider" />
                    <div className="tx-hash">
                      <div className="hash-details">
                        <span>Tx Hash</span>
                        <br />
                        <span className="tx-hash">
                          <span>
                            {minifyTxHash(transactionReceipt.transactionHash)}
                          </span>
                          <span>
                            <a
                              href={chain1.explorer + "tx/" + txHash}
                              target="#"
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                className="ext-link-image"
                              />
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                    {transactionDetails ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="date-details">
                          <span>Date</span>
                          <br />
                          <span>{transactionDetails.timestamp}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "error" ? (
                <>
                  <div className="progress-container">
                    <ImgDiv />
                    <FailedImg failed src={Failed} />
                    <div style={{ marginTop: "2rem" }}>
                      Transaction has Failed
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      <FailedPara>
                        Error Transaction was not mined within 100 blocks,
                        please make sure your transaction was properly sent. Be
                        aware that it might still be mined!
                      </FailedPara>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          {transactionType === "receiveTokens" ? (
            <>
              {txStatus === "pending" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>
                      <b>Transaction is Pending</b>
                    </div>
                  </div>
                  <div className="  hash-details-container">
                    <div className="date-details">
                      <span>Token Value</span>
                      <br />
                      <span>
                        {tokenValue} {sendToken.symbol}
                      </span>
                    </div>
                    {txHash ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="tx-hash">
                          <div className="hash-details">
                            <span>Tx Hash</span>
                            <br />
                            <span className="tx-hash">
                              <span>{minifyTxHash(txHash)}</span>
                              <span>
                                <a
                                  href={chain2.explorer + "tx/" + txHash}
                                  target="#"
                                >
                                  <FontAwesomeIcon
                                    icon={faExternalLinkSquareAlt}
                                    className="ext-link-image"
                                  />
                                </a>
                              </span>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "done" ? (
                <>
                  <div className="progress-container">
                    <div>
                      <ImgDiv />
                      <ApproveImg src={Approve} />
                    </div>
                    <div>
                      <b>Transaction Completed Successfully</b>
                    </div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token Value</span>
                      <br />
                      <span>
                        {tokenValue} {sendToken.symbol}
                      </span>
                    </div>
                    <hr className="hor-divider" />
                    <div className="tx-hash">
                      <div className="hash-details">
                        <span>Tx Hash</span>
                        <br />
                        <span className="tx-hash">
                          <span>
                            {minifyTxHash(transactionReceipt.transactionHash)}
                          </span>
                          <span>
                            <a
                              href={chain2.explorer + "tx/" + txHash}
                              target="#"
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                className="ext-link-image"
                              />
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                    {transactionDetails ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="date-details">
                          <span>Date</span>
                          <br />
                          <span>{transactionDetails.timestamp}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "error" ? (
                <>
                  <div className="progress-container">
                    <ImgDiv />
                    <FailedImg failed src={Failed} />
                    <div style={{ marginTop: "2rem" }}>
                      Transaction has Failed
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      <FailedPara>
                        Error Transaction was not mined within 100 blocks,
                        please make sure your transaction was properly sent. Be
                        aware that it might still be mined!
                      </FailedPara>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
          {transactionType === "minting" ? (
            <>
              {txStatus === "pending" ? (
                <>
                  <div className="progress-container">
                    <PendingImg className="pending-cube" src={Cube} />
                    <div>
                      <b>Transaction is Pending</b>
                    </div>
                  </div>
                  {/* {txHash ? ( */}
                  <div className="  hash-details-container">
                    <div className="date-details">
                      <span>Token Value</span>
                      <br />
                      <span>
                        {tokenValue} {sendToken.symbol}
                      </span>
                    </div>
                    {/* <hr className="hor-divider" /> */}
                    {/* <div className="tx-hash">
                        <div className="hash-details">
                          <span>Tx Hash</span>
                          <br />
                          <span className="tx-hash">
                            <span>{minifyTxHash(txHash)}</span>
                            <span>
                              <a
                                href={chain2.explorer + "tx/" + txHash}
                                target="#"
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  className="ext-link-image"
                                />
                              </a>
                            </span>
                          </span>
                        </div>
                      </div> */}
                  </div>
                  {/* ) : null} */}
                </>
              ) : null}
              {txStatus === "done" ? (
                <>
                  <div className="progress-container">
                    <div>
                      <ImgDiv />
                      <ApproveImg src={Approve} />
                    </div>
                    <div>
                      <b>Transaction Completed Successfully</b>
                    </div>
                  </div>
                  <div className="hash-details-container">
                    <div className="date-details">
                      <span>Token Value</span>
                      <br />
                      <span>
                        {tokenValue} {sendToken.symbol}
                      </span>
                    </div>
                    <hr className="hor-divider" />
                    <div className="tx-hash">
                      <div className="hash-details">
                        <span>Tx Hash</span>
                        <br />
                        <span className="tx-hash">
                          <span>
                            {minifyTxHash(transactionReceipt.transactionHash)}
                          </span>
                          <span>
                            <a
                              href={chain2.explorer + "tx/" + txHash}
                              target="#"
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                className="ext-link-image"
                              />
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                    {transactionDetails ? (
                      <>
                        <hr className="hor-divider" />
                        <div className="date-details">
                          <span>Date</span>
                          <br />
                          <span>{transactionDetails.timestamp}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {txStatus === "error" ? (
                <>
                  <div className="progress-container">
                    <ImgDiv />
                    <FailedImg failed src={Failed} />
                    <div style={{ marginTop: "2rem" }}>
                      Transaction has Failed
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      <FailedPara>
                        Error Transaction was not mined within 100 blocks,
                        please make sure your transaction was properly sent. Be
                        aware that it might still be mined!
                      </FailedPara>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </DialogDiv>
      </div>
    </div>
  );
};

export default DialogCard;
