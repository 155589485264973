import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";

import Bridge from "./components/Bridge";
import Footer from "./components/Footer";
import History from "./components/History";
import Navbar from "./components/Navbar";
import WalletContext from "./contexts/WalletContext";

import "./App.scss";

function App() {
  return (
    <div className="App dark">
      <Providers>
        <Router>
          <Navbar />
          <div>
            <Switch>
              <Route exact strict path="/" component={Bridge} />
              <Route path="/history" component={History} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </Providers>
    </div>
  );
}

const Providers = ({ children }) => {
  return (
    <WalletContext>
      {children}
    </WalletContext>
  );
};

export default App;
