import { ethers } from "ethers";
import React, { useState } from "react";
import { HelpCircle } from "react-feather";
import exchange from "../../assets/exchange.png";
import external from "../../assets/external.svg";
import {
  getChainByBridgeId,
  getDateString,
  getTokenByResourceId
} from "../../utils/utilsFunc";
import {
  AccordianCircleDiv,
  AccordianDestinationLink,
  AccordianLinkTextDestination,
  AccordianLinkTextSource,
  AccordianLogo,
  AccordianLogodiv,
  AccordianSourceLink, CancelText, CompleteText, ExternalImg,
  NameContent,
  NameHeading,
  NameSpan, PendingText, StyledAccordian, StyledAccordianTab, StyledSVG
} from "../index";

function Accordians({ userAggregatedData }) {

  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <>
      {
        userAggregatedData ? (
          <StyledAccordian
            multiple
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            {
              userAggregatedData.map((item) => (
                // <Accordian item={item} />
                <StyledAccordianTab
                  header={
                    <React.Fragment>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <AccordianLogodiv>
                          <AccordianLogo
                            src={
                              getChainByBridgeId(item.sourceChainID).image
                            }
                          />
                        </AccordianLogodiv>
                        <AccordianLogodiv>
                          <AccordianLogo
                            src={
                              getChainByBridgeId(item.destinationChainID)
                                .image
                            }
                          />
                        </AccordianLogodiv>
                        <div style={{ marginLeft: "0.6rem" }}>
                          <NameSpan style={{ marginLeft: "0rem" }}>
                            {
                              getChainByBridgeId(item.sourceChainID)
                                .shortName
                            }
                          </NameSpan>
                          <AccordianSourceLink
                            href={
                              getChainByBridgeId(item.sourceChainID)
                                .explorer +
                              "tx/" +
                              item.id
                            }
                            target="_blank"
                          >
                            <AccordianLinkTextSource>Source Trx</AccordianLinkTextSource>
                            <div style={{ marginTop: "-0.15rem" }}>
                              <ExternalImg src={external} />
                            </div>
                          </AccordianSourceLink>
                        </div>
                        <AccordianCircleDiv style={{ marginTop: "0rem" }}>
                          <img
                            style={{ marginTop: "0.5rem" }}
                            width="10"
                            height="10"
                            src={exchange}
                            alt="logo"
                          />
                        </AccordianCircleDiv>
                        <div style={{ marginLeft: "0.6rem" }}>
                          <NameSpan style={{ marginLeft: "0rem" }}>
                            {
                              getChainByBridgeId(item.destinationChainID)
                                .shortName
                            }
                          </NameSpan>
                          <AccordianDestinationLink
                            href={
                              item.executeProposal
                                ? getChainByBridgeId(
                                  item.destinationChainID
                                ).explorer +
                                "tx/" +
                                item.executeProposal?.id
                                : "#"
                            }
                            target={item.executeProposal ? "_blank" : ""}
                          >
                            <AccordianLinkTextDestination>Destination Trx</AccordianLinkTextDestination>
                            <div style={{ marginTop: "-0.15rem" }}>
                              <ExternalImg src={external} />
                            </div>
                          </AccordianDestinationLink>
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  <div>
                    <div style={{ display: "flex" }}>
                      <NameHeading>
                        Amount
                        <div style={{ marginTop: "0.2rem" }}>
                          <HelpCircle
                            style={{ marginLeft: "0.4rem" }}
                            size={14}
                          />
                        </div>
                      </NameHeading>
                      <NameContent
                        style={{ fontWeight: "500", color: "white" }}
                      >
                        {" "}
                        {ethers.utils.formatUnits(
                          ethers.BigNumber.from(item.amount).toString(),
                          getTokenByResourceId(item.resourceID).decimals[getChainByBridgeId(item.sourceChainID).chainId]
                        )}{" "}
                        {getTokenByResourceId(item.resourceID).symbol}
                      </NameContent>
                    </div>
                    <div style={{ display: "flex" }}>
                      <NameHeading>
                        Fee
                        <div style={{ marginTop: "0.2rem" }}>
                          <HelpCircle
                            style={{ marginLeft: "0.4rem" }}
                            size={14}
                          />
                        </div>
                      </NameHeading>
                      <NameContent>
                        {ethers.utils.formatEther(item.fee)}{" "}
                        {getChainByBridgeId(item.sourceChainID).symbol}
                      </NameContent>
                    </div>
                    <div style={{ display: "flex" }}>
                      <NameHeading>Status</NameHeading>
                      <NameContent>
                        {item.executeProposal ? (<CompleteText>Completed</CompleteText>) : null}
                        {!item.executeProposal && !item.voteProposalFailed ? <PendingText>Pending</PendingText> : null}
                        {item.voteProposalFailed ? <CancelText>Failed</CancelText> : null}
                      </NameContent>
                    </div>
                    <div style={{ display: "flex" }}>
                      <NameHeading>Date & Time</NameHeading>
                      <NameContent>
                        {" "}
                        {getDateString(item.timestamp).split(",")[0]} •{" "}
                        {getDateString(item.timestamp).split(",")[1]}
                      </NameContent>
                    </div>
                  </div>
                </StyledAccordianTab>
              ))
            }
          </StyledAccordian>
        ) : (
          <div style={{ marginLeft: "10rem" }}>
            <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </StyledSVG>
          </div>)
      }
    </>

  );
}

export default Accordians;
