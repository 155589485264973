import React, { useContext } from "react";
import { Context as WalletContext } from "../../contexts/WalletContext";
import { formatAddress } from "../../utils/utilsFunc";
import { MenuWalletButton } from "../index";
const WalletButton = () => {
  const { provider, loadWeb3Modal, logoutOfWeb3Modal } = useContext(
    WalletContext
  );

  return (
    <MenuWalletButton
      onClick={() => {
        provider ? logoutOfWeb3Modal() : loadWeb3Modal();
      }}
    >
      {provider ? formatAddress(provider.selectedAddress) : "Connect Wallet"}
    </MenuWalletButton>
  );
};

export default WalletButton;
