import {
  faArrowRight,
  faCircle,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addresses } from "@project/contracts";
import { ethers } from "ethers";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Typewriter from "typewriter-effect";
import * as Web3 from "web3";
import exchange from "../../assets/exchange.png";
import {
  CHAINS,
  DEFAULT_ASSET,
  DEFAULT_CHAIN,
  MINTER_ROLE,
} from "../../constants";
import { Context as WalletContext } from "../../contexts/WalletContext";
import useTransactionHistory from "../../hooks/useHistory";
import {
  getERC20SContract,
  getPSBridgeERC20,
  getPSBridgeERC20Chain2,
} from "../../utils/contractUtils";
import { formatAddress } from "../../utils/utilsFunc";
import AssetDialog from "../AssetDialog";
import ChainDialog from "../ChainDialog";
import DialogCard from "../DialogCard";
import {
  AddressHeading,
  ApproveButton,
  ApproveButtonText,
  BottomNumber,
  BottomNumberSmall,
  BottomText,
  CircleDiv,
  CircleDivTwo,
  CircleImgSmall,
  HistoryButton,
  LastDiv,
  MainHeading,
  MaxNumber,
  ModalHeader,
  NetworkDiv,
  NetworkHeading,
  PoolInfoItems,
  HistoryMessages,
  LinkSpan,
} from "../index";
import Tooltip from "../Tooltip";
import "./styles.scss";

const Bridge = () => {
  const BIG_INT_ZERO = ethers.BigNumber.from("0");
  const { provider, loadWeb3Modal } = useContext(WalletContext);

  const [tokenValue, setTokenValue] = useState("0");
  const [sendToken, setSendToken] = useState(DEFAULT_ASSET);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [userAllowanceNeeded, setUserAllowanceNeeded] = useState(false);
  const [tokenLiquidity, setTokenLiquidity] = useState(BIG_INT_ZERO);
  const [tokenContract, setTokenContract] = useState(null);
  const [buttonLabel, setButtonLabel] = useState("");
  const [buttonDisabledStatus, setButtonDisabledStatus] = useState();

  const [txHash, setTxHash] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [txStatus, setTxStatus] = useState(null);
  const [transactionReceipt, setTransactionReceipt] = useState(null);
  const [transactionType, setTransactionType] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [displayBasic3, setDisplayBasic3] = useState(false);
  const [value3, setValue3] = useState("");
  const [chain1, setChain1] = useState(DEFAULT_CHAIN);
  const [chain2, setChain2] = useState(DEFAULT_CHAIN);
  const [ethereumProtocolFee, setEthereumProtocolFee] = useState();

  const { userAggregatedData } = useTransactionHistory();

  const messages = useRef(null);
  const failedMessages = useRef(null);
  const msgs1 = useRef(null);

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
    displayBasic2: setDisplayBasic2,
    displayBasic3: setDisplayBasic3,
  };

  let history = useHistory();

  const handleRouteClick = useCallback(() => {
    history.push("/history");
  }, [history])

  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const selectChain1 = async (chain) => {
    try {
      await loadWeb3Modal();

      if (chain.chainId === "0x1") {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: chain.chainId,
            },
          ],
        });
      } else {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chain.chainId,
              chainName: chain.name,
              nativeCurrency: {
                name: chain.tokenName,
                symbol: chain.symbol,
                decimals: 18,
              },
              rpcUrls: [chain.rpcUrl],
              blockExplorerUrls: [chain.explorer],
            },
          ],
        });
      }
    } catch (err) {
      console.log("err: ", err);
      return;
    }
    setChain1(chain);

    getTokenContract();
    getTokenBalance();
    onHide("displayBasic");
  };

  const selectChain2 = (chain) => {
    setChain2(chain);
    onHide("displayBasic3");
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderHeader = (name) => {
    return <ModalHeader>Select From Network</ModalHeader>;
  };
  const renderHeaderTwo = (name) => {
    return <ModalHeader>Choose from Assets</ModalHeader>;
  };
  const renderHeaderThree = (name) => {
    return <ModalHeader>Select To Network</ModalHeader>;
  };
  const getTokenContract = useCallback(async () => {
    if (provider) {
      try {
        if (!sendToken.address[chain1.chainId]) {
          return;
        }
        const contractInstance = await getERC20SContract(
          provider,
          sendToken.address[chain1.chainId]
        );
        setTokenContract(contractInstance);
      } catch (error) {
        console.log(
          "%cMyProject%cline:119%cerror",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(3, 101, 100);padding:3px;border-radius:2px",
          error
        );
      }
    }
  }, [sendToken, provider, chain1]);

  const getTokenBalance = useCallback(async () => {
    try {
      if (tokenContract && provider && sendToken) {
        const balance = await tokenContract.methods
          .balanceOf(provider.selectedAddress)
          .call();
        const balanceBn = ethers.utils.formatUnits(balance, sendToken.decimals[chain1.chainId]);

        setUserTokenBalance(parseFloat(balanceBn).toFixed(2));
      } else {
        setUserTokenBalance(0);
      }
    } catch (error) { }
  }, [tokenContract, provider, sendToken, chain1.chainId]);

  const getTokenAllowance = useCallback(async () => {
    try {
      if (tokenContract && provider && chain1 !== DEFAULT_CHAIN) {
        const allowance = await tokenContract.methods
          .allowance(
            provider.selectedAddress,
            addresses.erc20Handler[chain1.chainId]
          )
          .call();
        const allowanceBn = ethers.BigNumber.from(allowance);

        if (tokenValue === "0" || tokenValue === "") {
          setUserAllowanceNeeded(false);
        } else {
          const tokenValueBN = ethers.utils.parseUnits(
            tokenValue,
            sendToken.decimals[chain1.chainId]
          );
          const allowanceNeeded = tokenValueBN.gt(allowanceBn);

          setUserAllowanceNeeded(allowanceNeeded);
        }
      } else {
        setUserAllowanceNeeded(false);
      }
    } catch (error) { }
  }, [tokenContract, provider, chain1, tokenValue, sendToken.decimals]);

  const getTokenLiquidity = useCallback(async () => {
    if (
      provider &&
      sendToken !== DEFAULT_ASSET &&
      chain1 !== DEFAULT_CHAIN &&
      chain2 !== DEFAULT_CHAIN
    ) {
      const chain2Provider = new Web3.providers.HttpProvider(chain2.rpcUrl);
      const tokenContract = await getERC20SContract(
        chain2Provider,
        sendToken.address[chain2.chainId]
      );
      try {
        const hasRole = await tokenContract.methods
          .hasRole(MINTER_ROLE, addresses.erc20Handler[chain2.chainId])
          .call();
        if (hasRole) {
          setTokenLiquidity(ethers.constants.MaxUint256);
        }
      } catch (error) {
        const handlerBalance = await tokenContract.methods
          .balanceOf(addresses.erc20Handler[chain2.chainId])
          .call();
        if (handlerBalance) {
          setTokenLiquidity(ethers.BigNumber.from(handlerBalance));
        }
      }

      // const allowance = await tokenContract.methods.allowance(provider.selectedAddress, addresses.erc20Handler[chain1.chainId]).call()
      // const allowanceBn = ethers.BigNumber.from(allowance);

      // if (tokenValue === "0" || tokenValue === "") {
      //   setUserAllowanceNeeded(false);
      // } else {
      //   const tokenValueBN = ethers.utils.parseEther(tokenValue)
      //   const allowanceNeeded = tokenValueBN.gt(allowanceBn)

      //   setUserAllowanceNeeded(allowanceNeeded);
      // }
    }
  }, [chain2, sendToken, provider, chain1]);

  const getProtocolFee = useCallback(async () => {
    if (chain2 !== DEFAULT_CHAIN && provider) {
      const id = chain2.bridgeId;
      const psBridgeERC20 = await getPSBridgeERC20(provider);
      const fee = await psBridgeERC20.methods._fee(id).call();

      setEthereumProtocolFee(
        parseFloat(ethers.utils.formatEther(fee)).toFixed(2)
      );
    }
  }, [provider, chain2]);

  const sendToBridge = async () => {
    if (chain1 === chain2) {
      failedMessages.current.show({
        sticky: true,
        severity: "error",
        summary: "Both chains cannot be same",
      });
      return;
    }

    if (chain1.chainId !== provider.chainId) {
      failedMessages.current.show({
        sticky: true,
        severity: "error",
        summary: "Switch Metamask to " + chain1.name,
      });
      return;
    }

    if (userTokenBalance === 0 || tokenValue === "0") {
      return;
    }

    if (tokenContract) {
      try {
        const psBridgeERC20 = await getPSBridgeERC20(provider);

        const tokenValueBN = ethers.utils.parseUnits(
          tokenValue,
          sendToken.decimals[chain1.chainId]
        );

        try {
          setTxStatus("waiting");
          setTransactionType("sendTokens");
          setModalVisible(true);

          const data =
            "0x" +
            ethers.utils.hexZeroPad(tokenValueBN.toHexString(), 32).substr(2) +
            ethers.utils
              .hexZeroPad(
                ethers.utils.hexlify((provider.selectedAddress.length - 2) / 2),
                32
              )
              .substr(2) +
            provider.selectedAddress.substr(2);

          // const id = provider.chainId === "0x507" ? 1 : 0;
          const id = chain2.bridgeId;

          psBridgeERC20.methods
            ._fee(id)
            .call()
            .then(function (depositFee) {
              psBridgeERC20.methods
                .deposit(id, sendToken.resourceId, data)
                .send({
                  from: provider.selectedAddress,
                  value: depositFee,
                })
                .on("transactionHash", function (hash) {
                  setTxHash(hash);
                  setTxStatus("pending");
                })
                .on("confirmation", function (confirmationNumber, receipt) {
                  getTokenBalance();
                })
                .on("receipt", function (receipt) {
                  setTransactionReceipt(receipt);
                  console.log("receipt: ", receipt);

                  const chainId2 = getPSBridgeERC20Chain2(
                    new Web3.providers.WebsocketProvider(chain2.wssUrl, {
                      timeout: 30000,
                      clientConfig: {
                        // Useful to keep a connection alive
                        keepalive: true,
                        keepaliveInterval: 60000, // ms
                      },
                      reconnect: {
                        auto: true,
                        maxAttempts: 50,
                        onTimeout: true,
                      },
                    }),
                    chain2.chainId
                  );

                  setTransactionType("receiveTokens");
                  setTxStatus("pending");
                  setTransactionReceipt({
                    transactionHash: "",
                  });
                  setTxHash(null);

                  chainId2.events
                    .ProposalEvent(
                      {
                        filter: {
                          depositNonce:
                            receipt.events.Deposit.returnValues.depositNonce,
                          status: "2",
                        }, // Using an array means OR: e.g. 20 or 23
                        fromBlock: 0,
                      },
                      function (error, event) {
                        console.log("event: ", event);
                        console.log("error: ", error);
                      }
                    )
                    .on("connected", function (subscriptionId) {
                      console.log("subscriptionId: ", subscriptionId);
                    })
                    .on("data", function (event) {
                      setTxStatus("done");
                      setTransactionReceipt({
                        transactionHash: event.transactionHash,
                      });
                      setTxHash(event.transactionHash);

                      setTransactionType("minting");
                      setTxStatus("pending");

                      chainId2.events
                        .ProposalEvent(
                          {
                            filter: {
                              depositNonce:
                                receipt.events.Deposit.returnValues
                                  .depositNonce,
                              status: "3",
                            }, // Using an array means OR: e.g. 20 or 23
                            fromBlock: 0,
                          },
                          function (error, event) { }
                        )
                        .on("connected", function (subscriptionId) { })
                        .on("data", function (event) {
                          setTxStatus("done");
                          setTransactionReceipt({
                            transactionHash: event.transactionHash,
                          });
                          setTxHash(event.transactionHash);
                        })
                        .on("changed", function (event) {
                          // remove event from local database
                        })
                        .on("error", function (error, receipt) {
                          // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                          console.log(error);
                        });
                    })
                    .on("changed", function (event) {
                      // remove event from local database
                    })
                    .on("error", function (error, receipt) {
                      // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                      console.log(error);
                    });
                })
                .on("error", function (error) {
                  setTxStatus("error");
                });
              // console.log("depositFee: ", depositFee);
            });
        } catch (error) {
          console.log(
            "%cMyProject%cline:138%cerror",
            "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
            "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
            "color:#fff;background:rgb(130, 57, 53);padding:3px;border-radius:2px",
            error
          );
        }
      } catch (error) {
        console.log(
          "%cMyProject%cline:152%cerror",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(217, 104, 49);padding:3px;border-radius:2px",
          error
        );
      }
    }
  };

  // const getFaucetTokens = async () => {
  //   if (!provider) {
  //     return;
  //   }
  //   if (
  //     provider.chainId !== CHAINS.Moonbeam.chainId &&
  //     provider.chainId !== CHAINS.Polygon.chainId
  //   ) {
  //     failedMessages.current.show({
  //       sticky: true,
  //       severity: "error",
  //       summary:
  //         "Current chain not supported please switch to a supported chain",
  //     });

  //     return;
  //   }
  //   setTxStatus("waiting");
  //   setTransactionType("faucet");
  //   setModalVisible(true);
  //   try {
  //     try {
  //       await window.ethereum.request({
  //         method: "wallet_watchAsset",
  //         params: {
  //           type: "ERC20", // Initially only supports ERC20, but eventually more!
  //           options: {
  //             address: "0x155d5D4A07D1a12008880E3a593f9143d553Ab36", // The address that the token is at.
  //             symbol: "PPC", // A ticker symbol or shorthand, up to 5 chars.
  //             decimals: 18, // The number of decimals in the token
  //             image:
  //               "https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png", // A string url of the token logo
  //           },
  //         },
  //       });
  //     } catch (error) {
  //       console.log("error: ", error);
  //     }
  //     const contractInstance = await getERC20SContract(
  //       provider,
  //       addresses.erc20s
  //     );
  //     contractInstance.methods
  //       .mintTokens()
  //       .send({ from: provider.selectedAddress })
  //       .on("transactionHash", function (hash) {
  //         setTxHash(hash);
  //         setTxStatus("pending");
  //       })
  //       .on("confirmation", function (_, receipt) {
  //         getTokenBalance();
  //       })
  //       .on("receipt", function (receipt) {
  //         setTransactionReceipt(receipt);
  //         setTxStatus("done");
  //       })
  //       .on("error", function (error) {
  //         setTxStatus("error");
  //       });
  //   } catch (error) {
  //     console.log(
  //       "%cMyProject%cline:291%cerror",
  //       "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
  //       "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
  //       "color:#fff;background:rgb(95, 92, 51);padding:3px;border-radius:2px",
  //       error
  //     );
  //   }
  // };

  const setUserAllowance = async () => {
    setTxStatus("waiting");
    setTransactionType("approve");
    setModalVisible(true);

    tokenContract.methods
      .approve(
        addresses.erc20Handler[provider.chainId],
        ethers.constants.MaxUint256
      )
      .send({
        from: provider.selectedAddress,
      })
      .on("transactionHash", function (hash) {
        setTxHash(hash);
        setTxStatus("pending");
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        getTokenBalance();
        getTokenAllowance();
      })
      .on("receipt", function (receipt) {
        setTransactionReceipt(receipt);
        setTxStatus("done");
      })
      .on("error", function (error) {
        console.log(
          "%cMyProject%cline:143%cerror",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(131, 175, 155);padding:3px;border-radius:2px",
          error
        );
        getTokenAllowance();
        setTxStatus("error");
      });
  };

  useEffect(() => {
    getTokenContract();
  }, [getTokenContract]);

  useEffect(() => {
    getTokenBalance();
  }, [getTokenBalance]);

  useEffect(() => {
    getTokenAllowance();
  }, [getTokenAllowance]);

  useEffect(() => {
    getProtocolFee();
  }, [getProtocolFee]);

  useEffect(() => {
    getTokenLiquidity();
    // console.log("qweqweqweqeqw", ethers.BigNumber.from("1.9876").toNumber())
  }, [getTokenLiquidity]);

  useEffect(() => {
    if (userAggregatedData?.length > 0) {
      msgs1.current.replace([
        {
          severity: "info",
          detail: (
            <span>
              Please review your latest transactions history:{" "}
              <LinkSpan onClick={handleRouteClick}>
                {" "}
                Transaction History{" "}
              </LinkSpan>
            </span>
          ),
          sticky: true,
        },
      ]);
    } else {
      msgs1.current.replace([])
    }
  }, [handleRouteClick, userAggregatedData]);

  // useEffect(() => {
  //   getButtonLabel()
  // }, [chain1, chain2, sendToken])

  const getButtonDisabled = useCallback(() => {
    if (!provider) {
      return true;
    }
    if (chain1 === DEFAULT_CHAIN) {
      return true;
    }
    if (chain2 === DEFAULT_CHAIN) {
      return true;
    }
    if (sendToken === DEFAULT_ASSET) {
      return true;
    }
    if (parseFloat(tokenValue) === 0 || !parseFloat(tokenValue)) {
      return true;
    }
    if (userAllowanceNeeded) {
      return true;
    }
    if (parseFloat(tokenValue) > parseFloat(userTokenBalance)) {
      return true;
    }
    if (
      tokenLiquidity.lt(ethers.utils.parseUnits(tokenValue, sendToken.decimals[chain2?.chainId]))
    ) {
      return true;
    }

    return false;
  }, [
    provider,
    chain1,
    chain2,
    sendToken,
    tokenLiquidity,
    userTokenBalance,
    tokenValue,
    userAllowanceNeeded,
  ]);

  const getButtonLabel = useCallback(() => {
    if (!provider) {
      return "Connect To Metamask";
    }
    if (sendToken === DEFAULT_ASSET) {
      return "Select Asset To Bridge";
    }
    if (chain1 === DEFAULT_CHAIN) {
      return "Select From Network";
    }
    if (chain2 === DEFAULT_CHAIN) {
      return "Select To Network";
    }
    if (sendToken === DEFAULT_ASSET) {
      return "Select An Asset";
    }
    if (tokenLiquidity === BIG_INT_ZERO) {
      return "Not Enough Liquidity";
    }
    if (parseFloat(tokenValue) > parseFloat(userTokenBalance)) {
      return "Not Enough Balance";
    }
    if (parseFloat(tokenValue) === 0 || !parseFloat(tokenValue)) {
      return "Invalid Amount";
    }
    if (
      tokenLiquidity.lt(ethers.utils.parseUnits(tokenValue, sendToken.decimals[chain2?.chainId]))
    ) {
      return "Not Enough Liquidity";
    }

    return "Send to " + chain2.shortName;
  }, [
    provider,
    chain1,
    chain2,
    sendToken,
    tokenLiquidity,
    userTokenBalance,
    tokenValue,
    BIG_INT_ZERO,
  ]);

  useEffect(() => {
    setButtonLabel(getButtonLabel());
    setButtonDisabledStatus(getButtonDisabled());
  }, [getButtonDisabled, getButtonLabel]);

  return (
    <div className="p-grid bridge">
      <Messages className="alert-message" ref={messages}></Messages>
      <Messages className="alert-message" ref={failedMessages}></Messages>
      <div className="p-col-12 p-lg-6 p-sm-12 bridge-details-container">
        <HistoryMessages className="info-message" ref={msgs1} />
        <MainHeading>
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  '<span style="color: #FF39F0;"></div>Demodyfi</span> is leveraging the specific functionality of '
                )
                .pauseFor(250)
                .typeString(
                  "<span>Polkadot Parachains while reaching users and assets </span>"
                )
                .pauseFor(250)
                .typeString(
                  '<span>on other <span style="color: #19D2FF;"></div>Blockchain Systems seamlessly.</span></span>'
                )
                .pauseFor(250)
                .start();
            }}
          />
        </MainHeading>
      </div>
      <div className="p-col-12 p-lg-6 p-sm-12 bridge-control-container">
        <div className="control-container">
          <div className="control-header" style={{ display: "flex" }}>
            <h2
              style={{
                color: "white",
              }}
            >
              Bridge
            </h2>
            {userAggregatedData?.length > 0 ? (<HistoryButton onClick={handleRouteClick}>History</HistoryButton>) : null}
          </div>

          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <div className="p-field">
              <span className="field-placeholder">
                Asset <Tooltip text={"This is the token you want to bridge"} />
              </span>
              <InputText
                optionLabel="label"
                className="token-dropdown"
                dataKey="address"
                value={sendToken.label}
                onClick={() => onClick("displayBasic2")}
                onChange={(e) => {
                  setSendToken(e.target.value);
                }}
              />
            </div>
            <AssetDialog
              displayBasic2={displayBasic2}
              renderHeaderTwo={renderHeaderTwo}
              onHide={onHide}
              value3={value3}
              setValue3={setValue3}
              setSendToken={setSendToken}
            />
            <div className="p-grid div-grid">
              <div className="p-col-5">
                <span className="field-placeholder">From </span>
                <NetworkDiv onClick={() => onClick("displayBasic")}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircleDivTwo>
                      <img
                        // style={{ marginTop: "0.67rem" }}
                        width="auto"
                        height="auto"
                        src={chain1.image}
                        alt="logo"
                      />
                    </CircleDivTwo>
                  </div>
                  <NetworkHeading>
                    {chain1.name}
                    <FontAwesomeIcon
                      style={{ width: "0.7rem", marginLeft: "1rem" }}
                      icon={faArrowRight}
                    />
                  </NetworkHeading>
                </NetworkDiv>
                {provider ? (
                  <AddressHeading>
                    {formatAddress(provider.selectedAddress)}{" "}
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="address-copy"
                      onClick={() => {
                        navigator.clipboard.writeText(provider.selectedAddress);
                        messages.current.show({
                          sticky: true,
                          severity: "success",
                          summary:
                            '"' +
                            provider.selectedAddress +
                            '"' +
                            " has been copied to clipboard",
                        });
                      }}
                    />
                  </AddressHeading>
                ) : null}
              </div>
              <div className="p-col-2">
                <CircleDiv
                  onClick={() => {
                    selectChain1(chain2);
                    selectChain2(chain1);
                  }}
                >
                  <img
                    style={{ marginTop: "0.8rem" }}
                    width="15"
                    height="15"
                    src={exchange}
                    alt="logo"
                  />
                </CircleDiv>
              </div>
              <div className="p-col-5">
                <span className="field-placeholder">To </span>
                <NetworkDiv onClick={() => onClick("displayBasic3")}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircleDivTwo>
                      <img
                        // style={{ marginTop: "0.67rem" }}
                        width="auto"
                        height="auto"
                        src={chain2.image}
                        alt="logo"
                      />
                    </CircleDivTwo>
                  </div>
                  <NetworkHeading>
                    {chain2.name}
                    <FontAwesomeIcon
                      style={{ width: "0.7rem", marginLeft: "1rem" }}
                      icon={faArrowRight}
                    />
                  </NetworkHeading>
                </NetworkDiv>
                {provider ? (
                  <AddressHeading
                    onClick={() => {
                      navigator.clipboard.writeText(provider.selectedAddress);
                      messages.current.show({
                        sticky: true,
                        severity: "success",
                        summary:
                          '"' +
                          provider.selectedAddress +
                          '"' +
                          " has been copied to clipboard",
                      });
                    }}
                  >
                    {formatAddress(provider.selectedAddress)}{" "}
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="address-copy"
                      onClick={() => {
                        navigator.clipboard.writeText(provider.selectedAddress);
                        messages.current.show({
                          sticky: true,
                          severity: "success",
                          summary:
                            '"' +
                            provider.selectedAddress +
                            '"' +
                            " has been copied to clipboard",
                        });
                      }}
                    />
                  </AddressHeading>
                ) : null}
              </div>
              <ChainDialog
                display={"displayBasic"}
                displayBasic={displayBasic}
                renderHeader={renderHeader}
                onHide={onHide}
                selectChain={selectChain1}
                chain1={undefined}
              />
              <ChainDialog
                display={"displayBasic3"}
                displayBasic={displayBasic3}
                renderHeader={renderHeaderThree}
                onHide={onHide}
                selectChain={selectChain2}
                chain1={chain1}
              />
            </div>
            {/* <div className="p-field">
              <span className="field-placeholder">
                Recipient{" "}
                <Tooltip
                  text={
                    "Faucet will provide you with all the tokens you may need to test out the bridge"
                  }
                />
              </span>
              <InputText
                className="token-input"
                value={recipientAddress}
                onChange={(e) => {
                  setRecipientAddress(e.target.value);
                }}
              />
            </div> */}
            {/* <div className="p-grid token-container"> */}

            <div className="p-field ">
              <span className="field-placeholder">
                Amount
                <Tooltip text={"Amount of tokens to bridge"} />
              </span>
              <div className="p-inputgroup">
                <InputText
                  className="token-input"
                  value={tokenValue}
                  onChange={(e) => {
                    setTokenValue(e.target.value);
                  }}
                />
                <MaxNumber
                  onClick={() => {
                    setTokenValue(userTokenBalance);
                  }}
                >
                  Max
                </MaxNumber>
              </div>
            </div>
            {/* <h5 style={{ color: "#FF39F0", margin: "0", textAlign: "left" }}>
              You’ve exceeded the max amount.
            </h5> */}
            {/* <div className="p-col-6 user-balance-container">
                Your Balance: {userTokenBalance}
              </div> */}
            {/* </div> */}
            <LastDiv>
              {/* <PoolInfoItems>
                <BottomText>
                  <FontAwesomeIcon
                    color="#8542F4"
                    style={{ width: "0.5rem", marginRight: "0.5rem" }}
                    icon={faCircle}
                  />
                  Max Available Amount
                  <span data-tip="Total number of tokens locked in this pool">
                    <FontAwesomeIcon
                      color="#19D2FF"
                      style={{ marginLeft: "0.4rem" }}
                      icon={faQuestionCircle}
                    />
                  </span>
                </BottomText>
                <BottomNumber style={{ color: "#ffffff" }}>
                  4,708,949,69342446 BTC
                </BottomNumber>
              </PoolInfoItems> */}
              {sendToken !== DEFAULT_ASSET && provider ? (
                <PoolInfoItems>
                  <BottomText>
                    <FontAwesomeIcon
                      color="#FFF4CC"
                      style={{ width: "0.5rem", marginRight: "0.5rem" }}
                      icon={faCircle}
                    />
                    Balance
                    <span>
                      <Tooltip text={"Balance of you tokens"} />
                    </span>
                  </BottomText>
                  <BottomNumberSmall style={{ color: "#ffffff" }}>
                    <span></span>
                    {userTokenBalance}{" "}
                    <CircleImgSmall>
                      <img
                        width="auto"
                        height="auto"
                        src={sendToken.image}
                        alt="logo"
                      />
                    </CircleImgSmall>{" "}
                    {sendToken.symbol}
                  </BottomNumberSmall>
                </PoolInfoItems>
              ) : null}
              {chain1 !== DEFAULT_CHAIN &&
                chain2 !== DEFAULT_CHAIN &&
                chain2 !== CHAINS.Ethereum ? (
                <PoolInfoItems>
                  <BottomText>
                    <FontAwesomeIcon
                      color="#FF9A36"
                      style={{ width: "0.5rem", marginRight: "0.5rem" }}
                      icon={faCircle}
                    />
                    Fee
                    <span>
                      <Tooltip text={"This is the protocol fees"} />
                    </span>
                  </BottomText>
                  <BottomNumber style={{ color: "#ffffff" }}>
                    0.99$ Approx
                  </BottomNumber>
                </PoolInfoItems>
              ) : null}

              {chain1 !== DEFAULT_CHAIN &&
                chain2 !== DEFAULT_CHAIN &&
                chain2 === CHAINS.Ethereum ? (
                <PoolInfoItems>
                  <BottomText>
                    <FontAwesomeIcon
                      color="#FF9A36"
                      style={{ width: "0.5rem", marginRight: "0.5rem" }}
                      icon={faCircle}
                    />
                    Fee
                    <span>
                      <Tooltip
                        text={
                          "This is the protocol fees, this includes the gas fees paid by the protocol for bridging on the Ethereum Network"
                        }
                      />
                    </span>
                  </BottomText>
                  <BottomNumber style={{ color: "#ffffff" }}>
                    {ethereumProtocolFee} {chain1.symbol}
                  </BottomNumber>
                </PoolInfoItems>
              ) : null}

              {chain1 !== DEFAULT_CHAIN &&
                chain2 !== DEFAULT_CHAIN &&
                sendToken !== DEFAULT_ASSET &&
                tokenLiquidity !== BIG_INT_ZERO ? (
                <PoolInfoItems>
                  <BottomText>
                    <FontAwesomeIcon
                      color="#8542f4"
                      style={{ width: "0.5rem", marginRight: "0.5rem" }}
                      icon={faCircle}
                    />
                    Bridge Liquidity
                    <span>
                      <Tooltip
                        text={"Liquidity of Bridge for selected token"}
                      />
                    </span>
                  </BottomText>
                  <BottomNumberSmall style={{ color: "#ffffff" }}>
                    <span></span>
                    {tokenLiquidity === ethers.constants.MaxUint256
                      ? "MAX"
                      : ethers.utils.formatUnits(
                        tokenLiquidity,
                        sendToken.decimals[chain2?.chainId]
                      )}
                    <CircleImgSmall>
                      <img
                        width="auto"
                        height="auto"
                        src={sendToken.image}
                        alt="logo"
                      />
                    </CircleImgSmall>{" "}
                    {sendToken.symbol}
                  </BottomNumberSmall>
                </PoolInfoItems>
              ) : null}
            </LastDiv>

            <div>
              {userAllowanceNeeded ? (
                <ApproveButton onClick={setUserAllowance}>
                  <ApproveButtonText>Approve</ApproveButtonText>
                </ApproveButton>
              ) : null}
              <Button
                label={buttonLabel}
                className="p-button-raised action-button p-button-rounded"
                onClick={() => {
                  if (!provider) {
                    loadWeb3Modal();
                    return;
                  }
                  sendToBridge();
                }}
                disabled={buttonDisabledStatus}
              />
            </div>
          </div>
        </div>
        {/* <div className="control-container">
          <div className="control-header">
            <h2
              style={{
                color: "white",
              }}
            >
              Faucet for Puppy Coin (PPC)
            </h2>
          </div>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <div>
              <Button
                label={
                  provider ? "Get Puppy Coin (PPC)" : "Connect To Metamask"
                }
                className="p-button-raised action-button p-button-rounded"
                onClick={() => {
                  if (!provider) {
                    loadWeb3Modal();
                  }
                  getFaucetTokens();
                }}
              />
            </div>
          </div>
        </div> */}
        {/* <h5 style={{ textAlign: "center" }}>
          you can view your{" "}
          <span style={{ color: "#FF9A36", textDecoration: "underline" }}>
            history
          </span>
        </h5> */}
        <DialogCard
          display={modalVisible}
          tokenValue={tokenValue}
          txStatus={txStatus}
          txHash={txHash}
          transactionReceipt={transactionReceipt}
          setDisplay={(displayBasic) => setModalVisible(displayBasic)}
          transactionType={transactionType}
          sendToken={sendToken}
          chain1={chain1}
          chain2={chain2}
        />
        {/* <DialogCard
          display={true}
          tokenValue={"5"}
          txStatus={"done"}
          txHash={
            "0x8fd20f09ad59f735ef50608731a0915651820a6b1c8065083937cdc6290936c0"
          }
          transactionReceipt={{
            transactionHash:
              "0x8fd20f09ad59f735ef50608731a0915651820a6b1c8065083937cdc6290936c0",
            blockNumber: "646542",
          }}
          setDisplay={(displayBasic) => setModalVisible(displayBasic)}
          transactionType={"faucet"}
          sendToken={sendToken}
        /> */}
      </div>
    </div>
  );
};

export default Bridge;
