import {
  faChevronDown, faCircle,
  faEllipsisH
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ethers } from "ethers";
import { Divider } from "primereact/divider";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  BookOpen, Box, Code, HelpCircle, Info,
  MessageCircle, MessageSquare
} from "react-feather";
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import dmod from "../../assets/dmod2.svg";
import external from "../../assets/external.svg";
import logo from "../../assets/logo.png";
import { CHAINS, DMOD } from "../../constants";
import { Context as WalletContext } from "../../contexts/WalletContext";
import usePrice from "../../hooks/usePrice";
import { formatAddress, getCurrentChain } from "../../utils/utilsFunc.js";
import AccountDialog from "../AccountDialog";
import Identicon from "../Identicon";
import {
  ConnectButtonText, ConnectedButton, DmodButton, DmodImg, DmodImgTablet,
  ExternalImg, Logo, LogoDiv, MenuFlyout, MenuFlyoutTablet, MenuItem, ModalHeader, NavbarDiv, NavIconButton, NavSpan, NavText, NetworkButton, NetworkContainer, NetworkIcon, NetworkItem, NetworkTitle, TabletConnectButtonText, TabletMenuDiv, WalletDiv, WalletSection
} from "../index";
import NetworkDropdown from "../NetworkDropdown";
import WalletButton from "../WalletButton";
import "./styles.scss";

function useToggle(initialValue = false) {
  const [value, setValue] = React.useState(initialValue);
  const toggle = React.useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle];
}

const Navbar = () => {
  const { provider } = useContext(WalletContext);
  const [isOn, toggleIsOn] = useToggle();
  const [showNetworkDropdown, setShowNetworkDropdown] = React.useState(false);
  // const [userDMODBalance, setUserDMODBalance] = React.useState(0);
  const [ethBalance, setEthBalance] = React.useState(0);

  let history = useHistory();

  const dmodPrice = usePrice("demodyfi");

  const [displayBasic, setDisplayBasic] = useState(false);

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const renderHeader = (name) => {
    return <ModalHeader style={{ fontSize: "16px", fontWeight: "500" }}>Account</ModalHeader>;
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  // const getUserDmodBalance = useCallback(async () => {
  //   if (provider) {
  //     try {
  //       const contractInstance = await getERC20SContract(
  //         provider,
  //         DMOD.address[provider.chainId]
  //       );

  //       const dmodBalance = await contractInstance.methods
  //         .balanceOf(provider.selectedAddress)
  //         .call();
  //       const dmodBalanceBn = ethers.utils.formatEther(dmodBalance);

  //       setUserDMODBalance(parseFloat(dmodBalanceBn).toFixed(2));
  //     } catch (error) {
  //       console.log(
  //         "%cMyProject%cline:119%cerror",
  //         "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
  //         "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
  //         "color:#fff;background:rgb(3, 101, 100);padding:3px;border-radius:2px",
  //         error
  //       );
  //     }
  //   }
  // }, [provider]);

  const getEthBalance = useCallback(async () => {
    if (provider) {
      try {
        const web3 = new Web3(provider);

        const balance = await web3.eth.getBalance(provider.selectedAddress);

        setEthBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(2));
      } catch (error) {
        console.log(
          "%cMyProject%cline:119%cerror",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(3, 101, 100);padding:3px;border-radius:2px",
          error
        );
      }
    }
  }, [provider]);

  // useEffect(() => {
  //   getUserDmodBalance();
  // }, [getUserDmodBalance]);

  useEffect(() => {
    getEthBalance();
  }, [getEthBalance]);

  const switchNetwork = async (chainId) => {
    const chain = getCurrentChain(chainId);

    if (chain.chainId === "0x1") {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: chain.chainId,
          },
        ],
      });
    } else {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: chain.chainId,
            chainName: chain.name,
            nativeCurrency: {
              name: chain.tokenName,
              symbol: chain.symbol,
              decimals: 18,
            },
            rpcUrls: [chain.rpcUrl],
            blockExplorerUrls: [chain.explorer],
          },
        ],
      });
    }
  };

  const addDmodAsset = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: DMOD.address[provider.chainId ?? CHAINS.Ethereum.chainId], // The address that the token is at.
              symbol: 'DMOD', // A ticker symbol or shorthand, up to 5 chars.
              decimals: 18, // The number of decimals in the token
              image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9713.png' // A string url of the token logo
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className="header-container">
      <AccountDialog
        display={"displayBasic"}
        displayBasic={displayBasic}
        renderHeader={renderHeader}
        onHide={onHide}
      />
      <NavbarDiv>
        <LogoDiv>
          <a href="https://www.demodyfi.com/" target="#">
            <Logo src={logo} />
          </a>
        </LogoDiv>
        <NavSpan>
          <span
            style={{ "textDecoration": "none" }}
            onClick={() => history.push("/")}
          >
            <NavText style={{ marginLeft: "0rem" }}>Home</NavText>
          </span>

          <a
            style={{ "textDecoration": "none" }}
            href="https://docs.demodyfi.com"
            target="#"
          >
            <NavText>About</NavText>{" "}
          </a>
          <a
            style={{ "textDecoration": "none" }}
            href="mailto:dev@demodyfi.com"
            target="#"
          >
            <NavText>Contact Us</NavText>{" "}
          </a>
          <a
            style={{ "textDecoration": "none" }}
            href="https://app.demodyfi.com"
            target="#"
          >
            <NavText>
              Dex <ExternalImg src={external} />
            </NavText>{" "}
          </a>
        </NavSpan>
        {provider ? (
          <NetworkContainer>
            <NetworkButton onClick={(e) => setShowNetworkDropdown(true)}>
              {getCurrentChain(provider.chainId) ? (
                <NetworkItem>
                  <NetworkIcon
                    src={getCurrentChain(provider.chainId).image}
                  ></NetworkIcon>
                  <NetworkTitle>
                    {getCurrentChain(provider.chainId).shortName}
                  </NetworkTitle>
                </NetworkItem>
              ) : (
                <NetworkItem>
                  <NetworkIcon
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Question_mark_white_icon.svg/1200px-Question_mark_white_icon.svg.png"
                    }
                  ></NetworkIcon>
                  <NetworkTitle>Invalid Network!</NetworkTitle>
                </NetworkItem>
              )}
              <FontAwesomeIcon icon={faChevronDown} color="white" />
            </NetworkButton>
            {showNetworkDropdown && (
              <NetworkDropdown
                setShowDropdown={setShowNetworkDropdown}
                switchNetwork={switchNetwork}
              />
            )}
          </NetworkContainer>
        ) : null}

        {provider ? (
          <DmodButton onClick={addDmodAsset}>
            <DmodImg src={dmod} />
            <ConnectButtonText>${dmodPrice.toFixed(3) ?? 0} </ConnectButtonText>
          </DmodButton>
        ) : null}
        {provider ? (
          <WalletSection>
            <WalletDiv>
              <FontAwesomeIcon
                color="#53F3C3"
                style={{ width: "0.5rem", marginRight: "0.5rem" }}
                icon={faCircle}
              />
              {ethBalance} GLMR
              {/* {getCurrentChain(provider.chainId)?.symbol ?? "NaN"} */}
            </WalletDiv>
            <ConnectedButton
              onClick={() => onClick("displayBasic")}
              // onClick={() => {
              //   logoutOfWeb3Modal();
              // }}
              style={{ display: "flex" }}
            >
              <ConnectButtonText>
                {formatAddress(provider.selectedAddress)}
              </ConnectButtonText>
              <Identicon />
            </ConnectedButton>
          </WalletSection>
        ) : (
          <WalletSection>
            {/* <WalletDiv>
              {" "}
              <FontAwesomeIcon
                color="#53F3C3"
                style={{ width: "0.5rem", marginRight: "0.5rem" }}
                icon={faCircle}
              />{" "}
              ETH
            </WalletDiv> */}
            <WalletButton>Connect Wallet</WalletButton>
          </WalletSection>
        )}
        {/* <IconDiv>
          <NavImg src={menu} />
        </IconDiv> */}
        {/* <NavMenu model={items} popup ref={menu} /> */}
        <NavIconButton onClick={toggleIsOn}>
          {" "}
          <FontAwesomeIcon color="white" icon={faEllipsisH} />
        </NavIconButton>
        {isOn ? (
          <div>
            <MenuFlyout>
              <MenuItem id="link" onClick={() => history.push("/")}>
                <Info size={14} />
                Home
              </MenuItem>
              <MenuItem id="link" target="_blank" href="https://demodyfi.com">
                <Info size={14} />
                About
              </MenuItem>
              <MenuItem
                id="link"
                target="_blank"
                href="https://docs.demodyfi.com"
              >
                <BookOpen size={14} />
                Docs
              </MenuItem>
              <MenuItem
                id="link"
                target="_blank"
                href={"https://github.com/Demodyfi"}
              >
                <Code size={14} />
                Code
              </MenuItem>
              <MenuItem id="link" target="_blank" href="https://discord.gg/zWDyzKQFH9">
                <MessageCircle size={14} />
                Discord
              </MenuItem>
            </MenuFlyout>
            <MenuFlyoutTablet>
              {provider ? (
                <div>
                  <TabletMenuDiv style={{ display: "flex" }}>
                    <FontAwesomeIcon
                      color="#53F3C3"
                      style={{
                        width: "0.5rem",
                        marginRight: "0.7rem",
                        marginLeft: "0.5rem",
                        marginTop: "0.2rem",
                      }}
                      icon={faCircle}
                    />
                    {ethBalance} {getCurrentChain(provider.chainId)?.symbol ?? "NaN"}
                  </TabletMenuDiv>
                  <TabletMenuDiv style={{ display: "flex", marginLeft: "0.3rem" }}>
                    <DmodImgTablet src={dmod} />
                    <TabletConnectButtonText
                      style={{ marginBottom: "10px", marginTop: "0.5rem" }}
                    >
                      $ {dmodPrice.toFixed(3) ?? 0}
                    </TabletConnectButtonText>
                  </TabletMenuDiv>
                  <Divider style={{ background: "#1f1f1f", height: "0.1px" }} />
                </div>
              ) : null}
              <MenuItem
                id="link"
                onClick={() => history.push("/")}
              >
                <HelpCircle size={14} />
                Home
              </MenuItem>

              <MenuItem id="link" target="_blank" href="https://demodyfi.com">
                <Info size={14} />
                About
              </MenuItem>
              <MenuItem
                id="link"
                target="_blank"
                href="https://docs.demodyfi.com"
              >
                <BookOpen size={14} />
                Docs
              </MenuItem>
              <MenuItem
                id="link"
                target="_blank"
                href={"https://github.com/Demodyfi"}
              >
                <Code size={14} />
                Code
              </MenuItem>
              <MenuItem id="link" target="_blank" href="https://discord.gg/zWDyzKQFH9">
                <MessageCircle size={14} />
                Discord
              </MenuItem>
              <MenuItem
                id="link"
                target="_blank"
                href="mailto:dev@demodyfi.com"
              >
                <MessageSquare size={14} />
                Contact Us
              </MenuItem>
              <MenuItem
                id="link"
                target="_blank"
                href="https://app.demodyfi.com"
              >
                <Box size={14} />
                Dex <sup>↗</sup>
              </MenuItem>
            </MenuFlyoutTablet>
          </div>
        ) : null}
      </NavbarDiv>
    </div>
  );
};

export default Navbar;
