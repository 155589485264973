import React from "react";
import { getChainsList } from "../../utils/utilsFunc";
import {
  ChainHeading,
  ChainSubHeading, ChooseDiv,
  CircleImg,
  DialogDiv,
  FirstDiv, InsideDiv,
  UpperDiv
} from "../index";
import "./styles.scss";


const ChainDialog = ({
  display,
  displayBasic,
  renderHeader,
  onHide,
  selectChain,
  chain1,
}) => {

  return (
    <DialogDiv
      visible={displayBasic}
      header={renderHeader(display)}
      onHide={() => onHide(display)}
    >
      <ChooseDiv>
        {getChainsList().map((chain) =>
          chain !== chain1 ? (
            <FirstDiv
              onClick={() => {
                selectChain(chain);
              }}
            >
              <InsideDiv
                style={{
                  display: "flex",
                  marginTop: "-2rem",
                  marginBottom: "-1.5rem",
                }}
              >
                <UpperDiv>
                  <CircleImg>
                    <img
                      width="auto"
                      height="auto"
                      src={chain.image}
                      alt="logo"
                      className="dropdown-img"
                    />
                  </CircleImg>
                </UpperDiv>
                <div>
                  <ChainHeading
                    style={{ marginBottom: "0", marginTop: "2rem" }}
                  >
                    {chain.name}
                  </ChainHeading>
                  <ChainSubHeading style={{ marginTop: 7 }}>
                    {chain.name}
                  </ChainSubHeading>
                </div>
              </InsideDiv>
            </FirstDiv>
          ) : null
        )}
      </ChooseDiv>
    </DialogDiv>
  );
};

export default ChainDialog;
