import React from "react";
import {
  DropdownOverlay,
  DropdownBox,
  DropdownHook,
  DropdownItem,
  NetworkIcon,
  NetworkTitle,
} from "./index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { getChainsList } from "../utils/utilsFunc";

function NetworkDropdown({ setShowDropdown, switchNetwork }) {
  return (
    <>
      <DropdownOverlay
        onClick={(e) => setShowDropdown(false)}
      ></DropdownOverlay>
      <DropdownBox>
        <DropdownHook></DropdownHook>
        {getChainsList().map((chain) => (
          <DropdownItem onClick={(e) => switchNetwork(chain.chainId)}>
            <NetworkIcon src={chain.image}></NetworkIcon>
            <NetworkTitle>{chain.shortName}</NetworkTitle>
            {window.ethereum.chainId === chain.chainId && (
              <FontAwesomeIcon icon={faCheck} color="green" />
            )}
          </DropdownItem>
        ))}
      </DropdownBox>
    </>
  );
}
export default NetworkDropdown;
