// import { Collapse } from "antd";
// const { Panel } = Collapse;
import {
  faGithub, faLinkedin, faMedium, faTelegram, faTwitter
} from "@fortawesome/free-brands-svg-icons";
import React, { useState } from "react";
import dmod from "../assets/dmod.png";
import AnalyticsDialog from "./AnalyticsDialog";
import { ActivityIcon, Demodyfi, FooterAnalyticsText, FooterDiv, FooterFontIcon, FooterIcon, FooterIconRow, FooterRow, FooterText, FooterTextTwo, ModalHeader } from "./index";

function Footer() {
  const [displayBasic, setDisplayBasic] = useState(false);

  const renderHeader = (name) => {
    return <ModalHeader style={{ fontSize: "16px", fontWeight: "500", marginBottom: '-3rem' }}></ModalHeader>;
  };
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };
  return (
    <div>
      {/* <FooterIconRow>
        <SocialSpan>Social</SocialSpan>
        <LineSpan></LineSpan>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://github.com/DemodyFI",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faGithub} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://twitter.com/DemodyFi",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faTwitter} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://t.me/DemodyFI",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faTelegram} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://demodyfi.medium.com/",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FontAwesomeIcon icon={faMedium} />
        </FooterIcon>
      </FooterIconRow> */}
      <AnalyticsDialog
        display={"displayBasic"}
        displayBasic={displayBasic}
        renderHeader={renderHeader}
        onHide={onHide}
      />
      <FooterDiv>
        <FooterRow style={{ padding: "1rem 0rem", flexGrow: 1 }}>
          <Demodyfi src={dmod} alt="icon" />
          <FooterText style={{ display: "flex", lineHeight: "19px" }}>
            © 2022 All Rights Reserved
          </FooterText>
          <FooterTextTwo href="https://bit.ly/31mO29W" target="_blank">
            Terms<span style={{ color: ' #302948', marginLeft: '0.5rem' }}> • </span>{' '}
          </FooterTextTwo>
          <FooterTextTwo href="https://drive.google.com/file/d/12Gkui0Q9ArmXde6T5MdxkuVTq2NWjQCF/view" target="_blank">
            Privacy<span style={{ color: ' #302948', marginLeft: '0.5rem' }}> • </span>{' '}
          </FooterTextTwo>
          <FooterTextTwo href="https://demodyfi.com" target="_blank">About</FooterTextTwo>
        </FooterRow>
        <FooterRow style={{ padding: '1rem 0rem', cursor: 'pointer' }}>
          <ActivityIcon size="20" />
          <FooterAnalyticsText onClick={() => onClick("displayBasic")} style={{ display: 'flex', lineHeight: '19px' }}>
            Analytics
          </FooterAnalyticsText>
        </FooterRow>
        <FooterIconRow>
          <FooterIcon
            style={{ marginLeft: '0.5rem' }}
            onClick={e => window.open('https://github.com/DemodyFI', '_blank', 'noopener noreferrer')}
          >
            <FooterFontIcon icon={faGithub} />
          </FooterIcon>
          <FooterIcon
            onClick={e => window.open('https://www.linkedin.com/company/demodyfi/', '_blank', 'noopener noreferrer')}
          >
            <FooterFontIcon icon={faLinkedin} />
          </FooterIcon>
          <FooterIcon onClick={e => window.open('https://twitter.com/DemodyFi', '_blank', 'noopener noreferrer')}>
            <FooterFontIcon icon={faTwitter} />
          </FooterIcon>
          <FooterIcon onClick={e => window.open('https://t.me/DemodyFI', '_blank', 'noopener noreferrer')}>
            <FooterFontIcon icon={faTelegram} />
          </FooterIcon>
          <FooterIcon onClick={e => window.open('https://demodyfi.medium.com/', '_blank', 'noopener noreferrer')}>
            <FooterFontIcon icon={faMedium} />
          </FooterIcon>
        </FooterIconRow>
      </FooterDiv>
    </div>
  );
}
export default Footer;
