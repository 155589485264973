import { gql } from "apollo-boost";

// See more example queries on https://thegraph.com/explorer/subgraph/paulrberg/create-eth-app

export const getBridgeDepositTransactions = (depositer) => {
  return gql`
  {
    tokenDeposits (first: 1000, where:{depositer: "${depositer.toLowerCase()}"}, orderBy: timestamp, orderDirection: desc) {
      id
      resourceID
      destinationChainID
      sourceChainID
      depositNonce
      depositer
      amount
      data
      timestamp
      fee
    }
  }  
  `;
};

export const getBridgeExecuteProposalTransactions = () => {
  return gql`
    {
      executeProposals(first: 1000) {
        id
        resourceID
        originChainID
        destinationChainID
        status
        dataHash
        depositNonce
      }
    }
  `;
};
