import React, { useEffect, useState } from "react";
import useTransactionHistory from "../../hooks/useHistory";
import { getChainByBridgeId } from "../../utils/utilsFunc";
import {
  AccordianDiv,
  HistoryHeading,
  PoolDiv,
  SearchSpan, StyledSVG, Table,
  TableDiv,
  TradeDropdown,
  TradeSearch
} from "../index";
import Accordians from "./Accordians";
import DataRow from "./DataRow";
import TableHeader from "./TableHeader";

const status = [
  { name: "All" },
  { name: "Pending" },
  { name: "Completed" },
  { name: "Failed" },
];

function History() {
  const [value3, setValue3] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({ name: "All" });

  const { userAggregatedData } = useTransactionHistory();
  const [filteredData, setFilteredData] = useState(null);

  const onStatusChange = (e) => {
    setSelectedStatus(e.value);
  };

  useEffect(() => {
    let data = []
    if (userAggregatedData) {

      // if (selectedStatus.name === "All") {
      //   dataArr = userAggregatedData
      // } else {
      //   dataArr = filteredData
      // }

      data = userAggregatedData.filter(
        (value, index, array) => {
          return (
            getChainByBridgeId(value.destinationChainID).shortName.toLowerCase().includes(value3) ||
            getChainByBridgeId(value.sourceChainID).shortName.toLowerCase().includes(value3)
          );
        }
      );

      if (selectedStatus?.name === "Completed") {
        data = data.filter(
          (value, index, array) => {
            return (
              value.executeProposal
            );
          }
        );
      }
      if (selectedStatus?.name === "Pending") {
        data = data.filter(
          (value, index, array) => {
            return (
              (!value.executeProposal && !value.voteProposalFailed)
            );
          }
        );
      }
      if (selectedStatus?.name === "Failed") {
        data = data.filter(
          (value, index, array) => {
            return (
              value.voteProposalFailed
            );
          }
        );
      }
      // if (selectedStatus?.name === "All") {
      //   data = data
      // }

      setFilteredData(data)
    }
  }, [value3, userAggregatedData, selectedStatus])

  // useEffect(() => {
  //   let data = []
  //   let dataArr = []

  //   if (userAggregatedData) {

  //     if (value3 === "") {
  //       dataArr = userAggregatedData
  //     } else {
  //       dataArr = filteredData
  //     }


  //     setFilteredData(data)
  //   }
  // }, [selectedStatus, userAggregatedData, value3])

  useEffect(() => {
    setFilteredData(userAggregatedData)
  }, [userAggregatedData])

  return (
    <div>
      <div>
        <div>
          <HistoryHeading>Bridge Transaction History</HistoryHeading>
          <PoolDiv>
            <div style={{ display: "flex" }}>
              <SearchSpan className="p-input-icon-right">
                <i className="pi pi-search" />
                <TradeSearch
                  value={value3}
                  onChange={(e) => setValue3(e.target.value)}
                  placeholder="Search By Chain Name"
                />
              </SearchSpan>
              <TradeDropdown
                value={selectedStatus}
                options={status}
                onChange={onStatusChange}
                optionLabel="name"
                placeholder="Select Status"
              />
            </div>
            <TableDiv>
              <Table>
                {
                  userAggregatedData?.length > 0 ? (
                    <>
                      <>
                        {filteredData?.length > 0 ? (
                          <>
                            <TableHeader />
                            {filteredData.map((item) => <DataRow item={item} />)}
                          </>
                        ) : (<p>No Data Found</p>)}
                      </>
                    </>
                  ) : <>
                    <div style={{ marginLeft: "36rem" }}>
                      <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </StyledSVG>
                    </div>
                  </>
                }
              </Table>
            </TableDiv>
            <AccordianDiv>
              <Accordians userAggregatedData={filteredData} />
            </AccordianDiv>
          </PoolDiv>
        </div>
      </div>
    </div>
  );
}

export default History;
