import ApolloClient from "apollo-boost";
import { useCallback, useContext, useEffect, useState } from "react";
import { Context as WalletContext } from "../contexts/WalletContext";
import {
  getBridgeDepositTransactions,
  getBridgeExecuteProposalTransactions
} from "../graphql/subgraph";
import { getChainsList } from "../utils/utilsFunc";

const useTransactionHistory = () => {
  const { provider } = useContext(WalletContext);

  // const [dataEthereum, setDataEthereum] = useState(null);
  // const [dataBsc, setDataBsc] = useState(null);
  // const [dataPolygon, setDataPolygon] = useState(null);
  // const [dataMoonbeam, setDataMoonbeam] = useState(null);
  const [userAggregatedData, setUserAggregatedData] = useState(null);

  // const clientEthereum = new ApolloClient({
  //     uri: "https://api.thegraph.com/subgraphs/name/jessupwall02/bridge-ethereum",
  // });
  // const clientBsc = new ApolloClient({
  //     uri: "https://api.thegraph.com/subgraphs/id/QmbSbKnb74bUwzxHbGr2LBadJKX71aRrhtCvc9pbahy3pB",
  // });
  // const clientPolygon = new ApolloClient({
  //     uri: "https://api.thegraph.com/subgraphs/name/jessupwall02/bridge-polygon",
  // });
  // const clientMoonbeam = new ApolloClient({
  //     uri: "https://api.thegraph.com/subgraphs/name/jessupwall02/bridge-moonbeam",
  // });

  const fetchData = useCallback(async () => {
    try {
      if (provider) {
        const chains = getChainsList();
        let depositData = await Promise.all(
          chains.map(async (chain) => {
            const client = new ApolloClient({
              uri: chain.subgraph,
            });

            const resp = await client.query({
              // query: getBridgeDepositTransactions("0xaa43c57e7aacbc4e7df83eac1582e93ba20a9351"),
              query: getBridgeDepositTransactions(provider.selectedAddress),
            });

            return resp.data.tokenDeposits;
          })
        );

        let aggregateDepositData = [];
        depositData.forEach((data) => {
          aggregateDepositData = aggregateDepositData.concat(data);
        });

        const executeData = await Promise.all(
          chains.map(async (chain) => {
            const client = new ApolloClient({
              uri: chain.subgraph,
            });

            const resp = await client.query({
              query: getBridgeExecuteProposalTransactions(),
            });

            return resp.data.executeProposals;
          })
        );

        let aggregateExecuteData = [];
        executeData.forEach((data) => {
          aggregateExecuteData = aggregateExecuteData.concat(data);
        });

        aggregateDepositData.forEach((data, index) => {
          const executedTransaction = aggregateExecuteData.filter(
            (value, index, array) => {
              return (
                value.depositNonce === data.depositNonce &&
                value.destinationChainID === data.destinationChainID &&
                value.originChainID === data.sourceChainID &&
                value.status === 3
              );
            }
          );
          aggregateDepositData[index]["executeProposal"] = executedTransaction[0];

          const voteProposals = aggregateExecuteData.filter(
            (value, index, array) => {
              return (
                value.depositNonce === data.depositNonce &&
                value.destinationChainID === data.destinationChainID &&
                value.originChainID === data.sourceChainID &&
                (value.status === 1 || value.status === 2)
              );
            }
          );

          const voteProposalFailed = aggregateExecuteData.filter(
            (value, index, array) => {
              return (
                value.depositNonce === data.depositNonce &&
                value.destinationChainID === data.destinationChainID &&
                value.originChainID === data.sourceChainID &&
                value.status === 4
              );
            }
          );

          aggregateDepositData[index]["executeProposal"] = executedTransaction[0];
          aggregateDepositData[index]["voteProposalFailed"] = voteProposalFailed[0];
          aggregateDepositData[index]["voteProposals"] = voteProposals;
        });
        aggregateDepositData.sort((a, b) => { return b.timestamp - a.timestamp })
        setUserAggregatedData(aggregateDepositData);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [provider]);

  // const fetchDataEthereum = useCallback(async () => {
  //     if (provider) {
  //         try {
  //             clientEthereum.query({
  //                 query: getBridgeDepositTransactions(provider.selectedAddress)
  //             }).then(result => setDataEthereum(result.data.tokenDeposits));
  //         } catch (error) {
  //             console.log("error: ", error);
  //         }
  //     }
  // }, [provider])

  // const fetchDataBsc = useCallback(async () => {
  //     if (provider) {
  //         try {
  //             clientBsc.query({
  //                 query: getBridgeDepositTransactions(provider.selectedAddress)
  //             }).then(result => setDataBsc(result.data.tokenDeposits));
  //         } catch (error) {
  //             console.log("error: ", error);
  //         }
  //     }
  // }, [provider])

  // const fetchDataPolygon = useCallback(async () => {
  //     if (provider) {
  //         try {
  //             clientPolygon.query({
  //                 query: getBridgeDepositTransactions(provider.selectedAddress)
  //             }).then(result => setDataPolygon(result.data.tokenDeposits));
  //         } catch (error) {
  //             console.log("error: ", error);
  //         }
  //     }
  // }, [provider])

  // const fetchDataMoonbeam = useCallback(async () => {
  //     if (provider) {
  //         try {
  //             clientMoonbeam.query({
  //                 query: getBridgeDepositTransactions(provider.selectedAddress)
  //             }).then(result => setDataMoonbeam(result.data.tokenDeposits));
  //         } catch (error) {
  //             console.log("error: ", error);
  //         }
  //     }
  // }, [provider])

  // const aggregateUserData = useCallback(async () => {
  //     if (dataEthereum && dataBsc && dataMoonbeam && dataPolygon) {
  //         const aggredatedData = dataEthereum.concat(dataBsc).concat(dataMoonbeam).concat(dataPolygon)
  //         aggredatedData.sort((a, b) => { return b.timestamp - a.timestamp })
  //         // setUserAggregatedData(aggredatedData)
  //     }
  // }, [dataEthereum, dataBsc, dataMoonbeam, dataPolygon])

  useEffect(() => {
    // fetchDataEthereum()
    // fetchDataBsc()
    // fetchDataPolygon()
    // fetchDataMoonbeam()
    fetchData();
    // }, [fetchDataEthereum, fetchDataBsc, fetchDataPolygon, fetchDataMoonbeam, fetchData]);
  }, [fetchData]);

  // useEffect(() => {
  //     aggregateUserData()
  // }, [aggregateUserData])

  // return { dataEthereum, dataBsc, dataPolygon, dataMoonbeam, userAggregatedData }
  return { userAggregatedData };
};

export default useTransactionHistory;
